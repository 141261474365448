import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	doc as firebaseDoc,
	endBefore,
	getDoc,
	getDocs,
	increment,
	limit,
	limitToLast,
	onSnapshot,
	orderBy,
	query,
	startAfter,
	updateDoc,
	where,
} from 'firebase/firestore';
import { GetEmailCreativeHTMLContent } from './email_creatives';
import { getClientDB, getOrganisationIdFirebase } from '../api/AxiosManager';
import { CampaignConstants } from '../constants/CampaignConstants';

export const GetSenderProfiles = async (communicationType) => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`${getOrganisationIdFirebase()}/Lookups/Sender_Profiles`
			),
			where('Communication_Type', '==', communicationType)
		);
		const docResult: any = await getDocs(qry);

		const resultArray = docResult.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));
		const dataSource = resultArray.map((item: any) => ({
			label: item.sender_profile,
			value: item.id,
		}));
		return dataSource;
	} catch (error) {
		return error;
	}
};
export const GetEmailSenderProfiles = async () => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`${getOrganisationIdFirebase()}/Lookups/Sender_Profile_Email`
			)
		);
		const docResult: any = await getDocs(qry);

		const resultArray = docResult.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));

		return resultArray;
	} catch (error) {
		return error;
	}
};
export const GetTimeZones = async () => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Lookups/Countries_Timezones`
			)
		);
		const querySnapshots = await getDocs(qry);

		return querySnapshots.docs.map((document: any) => document.data());
	} catch (error) {
		return error;
	}
};

export const GetUsers = async () => {
	try {
		const qry = query(
			collection(getClientDB(), `${getOrganisationIdFirebase()}/Users/Users`),
			where('Display', '!=', false)
		);
		const docResult: any = await getDocs(qry);
		const resultArray = docResult.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));
		const dataSource = resultArray.map((item: any) => ({
			label: `${item.FirstName} ${item.LastName}`,
			value: item.User_Id,
		}));
		return dataSource;
	} catch (error) {
		return error;
	}
};

export const checkSenderProfileEmail = async (senderProfileId) => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`${getOrganisationIdFirebase()}/Lookups/Sender_Profile_Email`
			),
			where('sender_profile', '==', senderProfileId)
		);
		const docResult: any = await getDocs(qry);
		const emailResult = docResult.docs.map((document: any) => document.data());
		if (emailResult.length) {
			return emailResult[0];
		}
		return false;
	} catch (error) {
		return error;
	}
};
export const checkSenderProfileSMS = async (senderProfileId) => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`${getOrganisationIdFirebase()}/Lookups/Sender_Profile_Sms`
			),
			where('sender_profile', '==', senderProfileId)
		);
		const docResult = await getDocs(qry);
		const smsResult = docResult.docs.map((document: any) => document.data());
		if (smsResult.length) {
			return smsResult[0];
		}
		return false;
	} catch (error) {
		return error;
	}
};

export const getVoicePhonePool = async (senderProfileId) => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`${getOrganisationIdFirebase()}/Lookups/Voice_Phone_Pool`
			),
			where('sender_voice_phone_pool_id', '==', senderProfileId)
		);
		const docResult = await getDocs(qry);
		const voiceResult = docResult.docs.map((document: any) => document.data());
		if (voiceResult.length) {
			return voiceResult[0];
		}
		return {};
	} catch (error) {
		return error;
	}
};

export const getPhonePool = async (id) => {
	try {
		const phonePool = await getDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}/Lookups/Phone_Pool`, id)
		);
		return phonePool.data();
	} catch (e) {
		return null;
	}
};

export const checkSenderProfileVoice = async (senderProfileId) => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`${getOrganisationIdFirebase()}/Lookups/Sender_Profile_Voice`
			),
			where('sender_profile', '==', senderProfileId)
		);
		const docResult = await getDocs(qry);
		const voiceResult = docResult.docs.map((document: any) => document.data());
		if (voiceResult.length) {
			return voiceResult[0];
		}
		return false;
	} catch (error) {
		return error;
	}
};

const updateCampaignsCount = async (val) => {
	const taskDocRef = firebaseDoc(
		getClientDB(),
		`/${getOrganisationIdFirebase()}/Counts`
	);
	try {
		await updateDoc(taskDocRef, { Campaigns: increment(val) });
		return {};
	} catch (err) {
		return null;
	}
};

const deleteHTMLInCampaign = async (campaignId, campaignCreativeId, htmlId) => {
	const taskDocRef = firebaseDoc(
		getClientDB(),
		`/${getOrganisationIdFirebase()}/Campaigns/Campaigns/${campaignId}/Creative/${campaignCreativeId}/HTML`,
		htmlId
	);
	try {
		await deleteDoc(taskDocRef);
		return {};
	} catch (err) {
		return null;
	}
};

const deleteCreativeInCampaign = async (campaignId, campaignCreativeId) => {
	const taskDocRef = firebaseDoc(
		getClientDB(),
		`/${getOrganisationIdFirebase()}/Campaigns/Campaigns/${campaignId}/Creative/`,
		campaignCreativeId
	);
	try {
		await deleteDoc(taskDocRef);
		return {};
	} catch (err) {
		return null;
	}
};

export const deleteCampaign = async (campaignId, campaignCreativeId, type) => {
	try {
		if (type === CampaignConstants.Email) {
			// Get HTML id of  creative in campaign
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Campaigns/Campaigns/${campaignId}/Creative/${campaignCreativeId}/HTML`
				)
			);
			const querySnapshots = await getDocs(qry);
			const resultArray: any = querySnapshots.docs.map((document: any) => ({
				id: document.id,
				...document.data(),
			}));
			const htmlId = resultArray[0].id;
			await deleteHTMLInCampaign(campaignId, campaignCreativeId, htmlId);
		}

		await deleteCreativeInCampaign(campaignId, campaignCreativeId);
		const taskDocRef = firebaseDoc(
			getClientDB(),
			`/${getOrganisationIdFirebase()}/Campaigns/Campaigns/`,
			campaignId
		);
		try {
			await deleteDoc(taskDocRef);
			updateCampaignsCount(-1);
			return {};
		} catch (err) {
			return null;
		}
	} catch (e) {
		return null;
	}
};

export const saveCampaign = async (data) => {
	try {
		const result = await addDoc(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Campaigns/Campaigns`
			),
			data
		);
		await updateCampaignsCount(1);
		return { id: result.id };
	} catch (e) {
		return e;
	}
};

export const getSenderProfileEmail = async (senderProfileId) => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`${getOrganisationIdFirebase()}/Lookups/Sender_Profile_Email`
			),
			where('sender_profile', '==', senderProfileId)
		);
		const docResult: any = await getDocs(qry);
		const emailResult = docResult.docs.map((document: any) => document.data());

		return emailResult;
	} catch (error) {
		return error;
	}
};

export const getSenderProfileSms = async (senderProfileId) => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`${getOrganisationIdFirebase()}/Lookups/Sender_Profile_Sms`
			),
			where('sender_profile', '==', senderProfileId)
		);
		const docResult: any = await getDocs(qry);
		const smsResult = docResult.docs.map((document: any) => document.data());

		return smsResult;
	} catch (error) {
		return error;
	}
};

export const getSenderProfileVoice = async (senderProfileId) => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`${getOrganisationIdFirebase()}/Lookups/Sender_Profile_Voice`
			),
			where('sender_profile', '==', senderProfileId)
		);
		const docResult: any = await getDocs(qry);
		const voiceResult = docResult.docs.map((document: any) => document.data());

		return voiceResult;
	} catch (error) {
		return error;
	}
};

const updateHTMLContent = async (html, campaignId, creativeId, htmlId) => {
	const creativeRef = firebaseDoc(
		getClientDB(),
		`/${getOrganisationIdFirebase()}/Campaigns/Campaigns/${campaignId}/Creative/${creativeId}/HTML/${htmlId}`
	);
	try {
		await updateDoc(creativeRef, { Content: html });
		return { id: htmlId };
	} catch (err) {
		return null;
	}
};

export const updateEmailCreative = async (
	data,
	campaignId,
	campaignCreativeId
) => {
	const creativeRef = firebaseDoc(
		getClientDB(),
		`/${getOrganisationIdFirebase()}/Campaigns/Campaigns/${campaignId}/Creative/${campaignCreativeId}`
	);
	try {
		await updateDoc(creativeRef, data);
		const qry = query(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Campaigns/Campaigns/${campaignId}/Creative/${campaignCreativeId}/HTML`
			)
		);
		const querySnapshots = await getDocs(qry);
		const resultArray = querySnapshots.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));
		const campaignCreativeHTMLId = resultArray[0].id;
		const result = await GetEmailCreativeHTMLContent(data.Creative.id);
		const htmlUpdatedResult = await updateHTMLContent(
			result[0],
			campaignId,
			campaignCreativeId,
			campaignCreativeHTMLId
		);
		return htmlUpdatedResult;
	} catch (err) {
		return null;
	}
};

export const saveCreativeInCampaign = async (data) => {
	try {
		const result = await addDoc(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Campaigns/Campaigns/${data.id}/Creative`
			),
			{ Creative: data.Creative }
		);
		// Get html content from email creative
		const htmlContentResult = await GetEmailCreativeHTMLContent(data.Creative.id);
		const htmlContent = htmlContentResult[0].Content;

		// Save html content into campaign creative - Cloning original creative
		const htmlSaveResult = await addDoc(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Campaigns/Campaigns/${data.id}/Creative/${
					result.id
				}/HTML`
			),
			{ Content: htmlContent }
		);
		return htmlSaveResult;
	} catch (err) {
		return null;
	}
};

export const saveCreativeInCampaignWithCustomVariables = async (
	data,
	variablesToReplace
) => {
	try {
		const result = await addDoc(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Campaigns/Campaigns/${data.id}/Creative`
			),
			{ Creative: data.Creative }
		);
		// Get html content from email creative
		const htmlContentResult = await GetEmailCreativeHTMLContent(data.Creative.id);
		const htmlContent = htmlContentResult[0].Content;

		const htmlContentAfterReplace = htmlContent.replace(
			variablesToReplace[0].name,
			variablesToReplace[0].value
		);
		// Save html content into campaign creative - Cloning original creative
		const htmlSaveResult = await addDoc(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Campaigns/Campaigns/${data.id}/Creative/${
					result.id
				}/HTML`
			),
			{ Content: htmlContentAfterReplace }
		);

		return htmlSaveResult;
	} catch (err) {
		return null;
	}
};

export const getSingleCampaignsCount = async () => {
	try {
		const docResult: any = await getDoc(
			doc(getClientDB(), getOrganisationIdFirebase(), 'Counts')
		);
		return docResult.data().Campaigns;
	} catch (error) {
		return error;
	}
};

export const getSingleChannelCampaigns = async ({
	firstDocument,
	lastDocument,
	rowsPerPage,
	type,
}) => {
	try {
		let querySnapshots: any;
		if (type === 'next') {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Campaigns/Campaigns`
				),
				orderBy('Campaign_Last_Modified_By', 'desc'),
				startAfter(lastDocument),
				limit(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		} else if (type === 'prev') {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Campaigns/Campaigns`
				),
				orderBy('Campaign_Last_Modified_By', 'desc'),
				endBefore(firstDocument),
				limitToLast(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		} else {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Campaigns/Campaigns`
				),
				orderBy('Campaign_Last_Modified_By', 'desc'),
				limit(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		}
		const resultArray = querySnapshots.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));
		return {
			data: resultArray,
			firstDocument: querySnapshots.docs[0],
			lastDocument: querySnapshots.docs[querySnapshots.docs.length - 1],
		};
	} catch (error) {
		return error;
	}
};

export const getCampaignsCreatedByMe = async ({
	firstDocument,
	lastDocument,
	rowsPerPage,
	type,
	userDocId,
}) => {
	try {
		let querySnapshots: any;
		if (type === 'next') {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Campaigns/Campaigns`
				),
				where('Campaign_Created_By.User_Doc_Id', '==', userDocId),
				orderBy('Campaign_Last_Modified_By', 'desc'),
				startAfter(lastDocument),
				limit(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		} else if (type === 'prev') {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Campaigns/Campaigns`
				),
				where('Campaign_Created_By.User_Doc_Id', '==', userDocId),
				orderBy('Campaign_Last_Modified_By', 'desc'),
				endBefore(firstDocument),
				limitToLast(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		} else {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Campaigns/Campaigns`
				),
				where('Campaign_Created_By.User_Doc_Id', '==', userDocId),
				orderBy('Campaign_Last_Modified_By', 'desc'),
				limit(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		}

		const resultArray = querySnapshots.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));

		return {
			data: resultArray,
			firstDocument: querySnapshots.docs[0],
			lastDocument: querySnapshots.docs[querySnapshots.docs.length - 1],
		};
	} catch (error) {
		return error;
	}
};

export const getCampaignsOwnedByMe = async ({
	firstDocument,
	lastDocument,
	rowsPerPage,
	type,
	userDocId,
}) => {
	try {
		let querySnapshots: any;
		if (type === 'next') {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Campaigns/Campaigns`
				),
				where('Campaign_Owner.User_Doc_Id', '==', userDocId),
				orderBy('Campaign_Last_Modified_By', 'desc'),
				startAfter(lastDocument),
				limit(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		} else if (type === 'prev') {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Campaigns/Campaigns`
				),
				where('Campaign_Owner.User_Doc_Id', '==', userDocId),
				orderBy('Campaign_Last_Modified_By', 'desc'),
				endBefore(firstDocument),
				limitToLast(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		} else {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Campaigns/Campaigns`
				),
				where('Campaign_Owner.User_Doc_Id', '==', userDocId),
				orderBy('Campaign_Last_Modified_By', 'desc'),
				limit(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		}

		const resultArray = querySnapshots.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));

		return {
			data: resultArray,
			firstDocument: querySnapshots.docs[0],
			lastDocument: querySnapshots.docs[querySnapshots.docs.length - 1],
		};
	} catch (error) {
		return error;
	}
};

export const getApprovalPendingCampaigns = async ({
	firstDocument,
	lastDocument,
	rowsPerPage,
	type,
	userDocId,
	approvalStatus,
}) => {
	try {
		let querySnapshots: any;
		if (type === 'next') {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Campaigns/Campaigns`
				),
				where('Campaign_Approver.User_Doc_Id', '==', userDocId),
				where('Approval_Status', '==', approvalStatus),
				orderBy('Campaign_Last_Modified_By', 'desc'),
				startAfter(lastDocument),
				limit(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		} else if (type === 'prev') {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Campaigns/Campaigns`
				),
				where('Campaign_Approver.User_Doc_Id', '==', userDocId),
				where('Approval_Status', '==', approvalStatus),
				orderBy('Campaign_Last_Modified_By', 'desc'),
				endBefore(firstDocument),
				limitToLast(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		} else {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Campaigns/Campaigns`
				),
				where('Campaign_Approver.User_Doc_Id', '==', userDocId),
				where('Approval_Status', '==', approvalStatus),
				orderBy('Campaign_Last_Modified_By', 'desc'),
				limit(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		}

		const resultArray = querySnapshots.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));
		return {
			data: resultArray,
			firstDocument: querySnapshots.docs[0],
			lastDocument: querySnapshots.docs[querySnapshots.docs.length - 1],
		};
	} catch (error) {
		return null;
	}
};

export const UpdateCampaignDetails = async (payload, campaignDocId) => {
	try {
		const result = await updateDoc(
			doc(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Campaigns/Campaigns/${campaignDocId}`
			),

			payload
		);
		return result;
	} catch (error) {
		return error;
	}
};

export const getCampaignDetails = (id): any =>
	new Promise((resolve, reject) => {
		try {
			const q = firebaseDoc(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Campaigns/Campaigns/${id}`
			);
			onSnapshot(q, (querySnapshot) => {
				const result = {
					...querySnapshot.data(),
					id: querySnapshot.id,
				};
				resolve(result);
			});
		} catch (error) {
			reject(error);
		}
	});

export const getCampaignCreativeDetails = async (id) => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`${getOrganisationIdFirebase()}/Campaigns/Campaigns/${id}/Creative`
			)
		);

		const docResult: any = await getDocs(qry);

		const resultArray = docResult.docs.map((document: any) => ({
			...document.data(),
			campaignCreativeId: document.id,
		}));
		return resultArray.length > 0 ? resultArray[0] : {};
	} catch (error) {
		return error;
	}
};
