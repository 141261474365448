import User from 'models/User';
import { LOGGING_IN, LOGIN_FAIL, LOGIN_SUCCESS, LOGGED_OUT, UserDispatchTypes, USER, USER_POSTED, SET_COMPANY_LOGO_UPDATED } from "./UserActionTypes";

interface IUser {
    user: User,
    loggedIn: boolean,
    loginFailed: boolean ,
    companyLogoUpdated : boolean
}

const initialState: IUser = {
    user: null,
    loggedIn: false,
    loginFailed: false ,
    companyLogoUpdated : false 
}

const userReducer = (state: any = initialState, action: UserDispatchTypes): IUser => {
    switch (action.type) {
        case LOGGING_IN:
            return { ...state, loggedIn: false, loginFailed: false };
        case LOGIN_FAIL:
            return { ...state, loggedIn: false, loginFailed: true };
        case LOGIN_SUCCESS:
            return { ...state, loggedIn: true, loginFailed: false };
        case LOGGED_OUT:
            return { ...initialState };
        case USER_POSTED:
            return { ...initialState, user: action.user, loggedIn: true, loginFailed: false };
        
        case USER: {
            // let result: string = '';
            // if (action.user.permissions.indexOf('Admin') !== -1) {
            //     result = 'Admin';
            // } else if (action.user.Roles.indexOf('Agency') !== -1) {
            //     result = '/Agency';
            // } else if (action.user.Roles.indexOf('Client') !== -1) {
            //     result = '/Home';
            // }
            return { ...state, loggedIn: true, user: action.user };
        }
        case SET_COMPANY_LOGO_UPDATED : 
        return { ...initialState, companyLogoUpdated : !initialState.companyLogoUpdated };
        default:
            return state;
    }
}


export default userReducer;