import {
	getAuth,
	GoogleAuthProvider,
	signInWithPopup,
	FacebookAuthProvider,
	TwitterAuthProvider,
	createUserWithEmailAndPassword,
	sendEmailVerification,
	signInWithEmailAndPassword,
	sendPasswordResetEmail,
	verifyPasswordResetCode,
	confirmPasswordReset,
	applyActionCode,
	signInWithCustomToken,
	onAuthStateChanged,
} from 'firebase/auth';
import {
	cloudBaseUrl,
	createWithXProjKey,
	getClientDB,
	getOrganisationIdFirebase,
	getUser,
	siteConfigConstants,
} from 'api/AxiosManager';
import {
	addDoc,
	collection,
	doc,
	getDoc,
	getDocs,
	query,
	where,
	onSnapshot,
	updateDoc,
	orderBy,
} from 'firebase/firestore';
import { masterDb, masterapp } from 'firebaseConfig';
import firebase from 'firebase/compat/app';
import moment from 'moment';
import { INewUser } from 'views/NewSignUp/onboardInterfaces';
import { INewOrgInitialState } from 'reducers/CreateOrg';
import CryptoJS from 'crypto-js';

const masterAuth: any = getAuth(masterapp);
let clientApp = null;

export const getCustomAccessToken = async (payload, authUrl = '') => {
	try {
		const url = authUrl || siteConfigConstants().REACT_APP_MISCELLANEOUS_URL;
		const instance = createWithXProjKey();
		const result = await instance.post(`${url}/getCustomUserToken`, payload);
		return result;
	} catch (e) {
		return null;
	}
};

export const GetAllCountries = (): any =>
	new Promise((resolve, reject) => {
		try {
			const q = query(collection(masterDb, `master/Lookups/Clients_Countries`));
			onSnapshot(q, (querySnapshot) => {
				const countries = querySnapshot.docs.map((item) => ({
					...item.data(),
					id: item.id,
				}));
				resolve(countries);
			});
		} catch (error) {
			reject(error);
		}
	});

export const GetAllIndustries = (): any =>
	new Promise((resolve, reject) => {
		try {
			const q = query(collection(masterDb, `/Industries`), orderBy('Name', 'asc'));
			onSnapshot(q, (querySnapshot) => {
				const industries = querySnapshot.docs.map((item) => ({
					...item.data(),
					id: item.id,
				}));
				resolve(industries);
			});
		} catch (error) {
			reject(error);
		}
	});

export const GetTimeZones = async () => {
	try {
		const qry = query(collection(masterDb, '/model/Lookups/Countries_Timezones'));
		const docResult: any = await getDocs(qry);
		const resultArray = docResult.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));
		return resultArray;
	} catch (error) {
		return error;
	}
};

export const GetContactsPrices = async (Country: any) => {
	try {
		const docRef = doc(
			masterDb,
			'master/Prices/Prices',
			JSON.parse(Country).value
		);
		const result = await getDoc(docRef);
		return result.data();
	} catch (error) {
		return error;
	}
};

export const BasicRegister = async (payload: INewUser) => {
	const auth = getAuth(masterapp);
	let result: any = null;
	try {
		const signupResult = await createUserWithEmailAndPassword(
			auth,
			payload.Email,
			payload.Password
		);
		const { user } = signupResult;
		if (user) {
			await sendEmailVerification(user);
			const encryptedPassword = CryptoJS.AES.encrypt(
				payload.Password,
				'LEAP360'
			).toString();
			const addDocResult = await addDoc(collection(masterDb, 'Users'), {
				...payload,
				Email: payload.Email.toLowerCase(),
				userRegistrationType: 'basic',
				firstLogin: true,
				OlderPasswords: [
					{ password: encryptedPassword, createdAt: moment().toString() },
				],
				Password: encryptedPassword,
				createdOn: moment().toString(),
				createdBy: 'System',
				UID: user.uid,
			});
			result = {
				success: true,
				data: addDocResult,
			};
		} else {
			result = {
				success: false,
			};
		}
		return result;
	} catch (error) {
		let errorMessage = '';
		if (error.code === 'auth/email-already-in-use') {
			errorMessage = 'Email already in use';
		} else if (error.code === 'auth/invalid-email') {
			errorMessage = 'Invalid email';
		} else if (error.code === 'auth/weak-password') {
			errorMessage = 'Password is weak';
		} else {
			errorMessage = error.message;
		}
		return {
			success: false,
			message: errorMessage,
			error: errorMessage,
		};
	}
};

export const BasicLogin = async (payload: any) => {
	const auth = getAuth(masterapp);
	try {
		const signInResult: any = await signInWithEmailAndPassword(
			auth,
			payload.Email,
			payload.Password
		);
		const userSnapshots = await getDocs(
			query(
				collection(masterDb, 'Users'),
				where('Email', '==', signInResult?.user?.email)
			)
		);
		const resultArray = userSnapshots.docs.map((document: any) => ({
			User_Id: document.id,
			...document.data(),
		}));
		return {
			success: true,
			data: {
				...resultArray[0],
				emailVerified: signInResult.user.emailVerified,
				currentUser: signInResult.user,
			},
		};
	} catch (error) {
		let errorMessage = '';
		if (error.code === 'auth/user-not-found') {
			errorMessage = 'User not found';
		} else if (error.code === 'auth/wrong-password') {
			errorMessage = 'Wrong password';
		} else if (error.code === 'auth/user-disabled') {
			errorMessage = 'User disabled';
		} else if (error.code === 'auth/too-many-requests') {
			errorMessage = 'Too many requests';
		} else if (error.code === 'auth/invalid-email') {
			errorMessage = 'Invalid email';
		}
		return {
			success: false,
			error: errorMessage,
		};
	}
};

export const resendEmailVerification = async (currentUser) => {
	try {
		const result = await sendEmailVerification(currentUser);
		return result;
	} catch (error) {
		return error;
	}
};

export const sendPasswordResetLink = async (email) => {
	let result;
	try {
		const userSnapsshots = await getDocs(
			query(collection(masterDb, 'Users'), where('Email', '==', email))
		);
		if (userSnapsshots.size > 0) {
			const auth = getAuth(masterapp);
			const response = await sendPasswordResetEmail(auth, email);
			result = {
				success: true,
				data: response,
			};
		} else {
			result = {
				success: false,
				message: 'User not found',
			};
		}
		return result;
	} catch (error) {
		return error;
	}
};

export const ConfirmPasswordReset = async (newPassword, code) => {
	const auth = getAuth(masterapp);
	try {
		const result = await confirmPasswordReset(auth, code, newPassword);
		return result;
	} catch (error) {
		return error;
	}
};

export const ConfirmEmailverification = async (code) => {
	const auth = getAuth(masterapp);
	try {
		const result = await applyActionCode(auth, code);
		return result;
	} catch (error) {
		return error;
	}
};

export const verifyoobCode = async (code) => {
	const auth = getAuth(masterapp);
	try {
		const result = await verifyPasswordResetCode(auth, code);
		return result;
	} catch (error) {
		return error;
	}
};

export const GoogleSignIn = async () => {
	const auth = getAuth(masterapp);
	const provider = new GoogleAuthProvider();
	try {
		const response: any = await signInWithPopup(auth, provider);
		const { displayName, email, photoURL } = response.user.providerData[0];
		const { uid } = response.user;
		let result: any = null;

		const userSnapshots = await getDocs(
			query(collection(masterDb, 'Users'), where('Email', '==', email))
		);
		// checking if user is already registered or not
		const resultArray = userSnapshots.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));

		let userSnapshotsTwoWayAuth: any = {};

		if (resultArray.length > 0) {
			userSnapshotsTwoWayAuth = await getDocs(
				query(
					collection(masterDb, 'User2WayAuthentication'),
					where('userid', '==', resultArray[0].id)
				)
			);
		}

		const resultArrayTwoWayAuth =
			userSnapshotsTwoWayAuth?.docs?.map((document: any) => ({
				twoWayAuthId: document.id,
				isGoogleAuthVerfied: document.isGoogleAuthVerfied,
				isSMSAuthVerfied: document.isSMSAuthVerfied,
				...document.data(),
			})) || [];

		const userData: INewUser = {
			FirstName: displayName.split(' ')[0] || '',
			LastName: displayName.split(' ')[1] || '',
			Name: displayName,
			Email: email,
			ProfilePicUrl: photoURL,
			...(resultArray.length && { User_Id: resultArray[0].id }),
			UID: uid,
			Password: '',
			ConfPassword: '',
			email_optIn: false,
			sms_optIn: false,
			voice_optIn: false,
			select_Phone_Type: '',
			landline_ext: '',
			phone_countryCode: '',
			phoneNumber: '',
			userRegistrationType: 'google',
			isGoogleAuthEnabled:
				resultArrayTwoWayAuth.length > 0
					? resultArrayTwoWayAuth[0].isGoogleAuthVerfied
					: false,
			isSMSAuthEnabled:
				resultArrayTwoWayAuth.length > 0
					? resultArrayTwoWayAuth[0].isSMSAuthVerfied
					: false,
		};

		// adding user to firestore if user not exists
		if (resultArray.length === 0) {
			userData.createdOn = moment().toString();
			userData.createdBy = 'System';
			const user = await addDoc(collection(masterDb, 'Users'), userData);
			userData.User_Id = user.id;
		}

		result = {
			success: true,
			data: { ...userData, emailVerified: true },
		};

		return result;
	} catch (error) {
		return error;
	}
};

export const FacebookSignIn = async () => {
	const auth = getAuth(masterapp);
	const provider = new FacebookAuthProvider();
	try {
		let result: any = await signInWithPopup(auth, provider);
		const { displayName, photoURL, uid } = result.user.providerData[0];

		const userSnapshots = await getDocs(
			query(collection(masterDb, 'Users'), where('UID', '==', result.user.uid))
		);
		const resultArray = userSnapshots.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));

		let userSnapshotsTwoWayAuth: any = {};

		if (resultArray.length > 0) {
			userSnapshotsTwoWayAuth = await getDocs(
				query(
					collection(masterDb, 'User2WayAuthentication'),
					where('userid', '==', resultArray[0].id)
				)
			);
		}

		const resultArrayTwoWayAuth =
			userSnapshotsTwoWayAuth?.docs?.map((document: any) => ({
				twoWayAuthId: document.id,
				isGoogleAuthVerfied: document.isGoogleAuthVerfied,
				isSMSAuthVerfied: document.isSMSAuthVerfied,
				...document.data(),
			})) || [];

		const displayNameArray = displayName.split(' ');
		const [FirstName, LastName] = displayNameArray;
		const userData: INewUser = {
			Name: displayName,
			Email: `${uid}@facebook.com`,
			ProfilePicUrl: photoURL,
			...(resultArray.length && { User_Id: resultArray[0].id }),
			UID: result.user.uid,
			Password: '',
			FirstName: FirstName ?? '',
			LastName: LastName ?? '',
			ConfPassword: '',
			email_optIn: false,
			sms_optIn: false,
			voice_optIn: false,
			select_Phone_Type: '',
			landline_ext: '',
			phone_countryCode: '',
			phoneNumber: '',
			userRegistrationType: 'facebook',
			isGoogleAuthEnabled:
				resultArrayTwoWayAuth.length > 0
					? resultArrayTwoWayAuth[0].isGoogleAuthVerfied
					: false,
			isSMSAuthEnabled:
				resultArrayTwoWayAuth.length > 0
					? resultArrayTwoWayAuth[0].isSMSAuthVerfied
					: false,
		};
		if (resultArray.length === 0) {
			userData.createdOn = moment().toString();
			userData.createdBy = 'System';
			const user = await addDoc(collection(masterDb, 'Users'), userData);
			userData.User_Id = user.id;
		}

		result = {
			success: true,
			data: { ...userData, emailVerified: true },
		};

		return result;
	} catch (error) {
		return error;
	}
};

export const TwitterSignIn = async () => {
	const auth = getAuth(masterapp);
	const provider = new TwitterAuthProvider();
	try {
		const response: any = await signInWithPopup(auth, provider);
		const { displayName, uid, photoURL } = response.user.providerData[0];
		let result: any = null;

		const userSnapshots = await getDocs(
			query(collection(masterDb, 'Users'), where('UID', '==', response.user.uid))
		);
		// checking if user is already registered or not
		const resultArray = userSnapshots.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));

		let userSnapshotsTwoWayAuth: any = {};

		if (resultArray.length > 0) {
			userSnapshotsTwoWayAuth = await getDocs(
				query(
					collection(masterDb, 'User2WayAuthentication'),
					where('userid', '==', resultArray[0].id)
				)
			);
		}

		const resultArrayTwoWayAuth =
			userSnapshotsTwoWayAuth?.docs?.map((document: any) => ({
				twoWayAuthId: document.id,
				isGoogleAuthVerfied: document.isGoogleAuthVerfied,
				isSMSAuthVerfied: document.isSMSAuthVerfied,
				...document.data(),
			})) || [];

		const firstName = displayName.split(' ')[0];
		const lastName = displayName.split(' ')[1] ?? '';
		const userData: INewUser = {
			Name: displayName,
			Email: `${uid}@twitter.com`,
			ProfilePicUrl: photoURL,
			...(resultArray.length && { User_Id: resultArray[0].id }),
			UID: response.user.uid,
			Password: '',
			FirstName: firstName,
			LastName: lastName,
			ConfPassword: '',
			email_optIn: false,
			sms_optIn: false,
			voice_optIn: false,
			select_Phone_Type: '',
			landline_ext: '',
			phone_countryCode: '',
			phoneNumber: '',
			userRegistrationType: 'twitter',
			isGoogleAuthEnabled:
				resultArrayTwoWayAuth.length > 0
					? resultArrayTwoWayAuth[0].isGoogleAuthVerfied
					: false,
			isSMSAuthEnabled:
				resultArrayTwoWayAuth.length > 0
					? resultArrayTwoWayAuth[0].isSMSAuthVerfied
					: false,
		};

		// adding user to firestore if user not exists
		if (resultArray.length === 0) {
			userData.createdOn = moment().toString();
			userData.createdBy = 'System';
			const user = await addDoc(collection(masterDb, 'Users'), userData);
			userData.User_Id = user.id;
		}

		result = {
			success: true,
			data: { ...userData, emailVerified: true },
		};

		return result;
	} catch (error) {
		return error;
	}
};

export const checkIfOrganizationExists = async (name: any) => {
	try {
		const orgSnapshots = await getDocs(
			query(collection(masterDb, 'UsersMapping'), where('Org_Name', '==', name))
		);
		let result = '';
		if (orgSnapshots.size === 0) {
			result = 'yes';
		} else {
			result = 'no';
		}
		return result;
	} catch (error) {
		return error;
	}
};

export const CreateOrganisation = async ({
	newOrgData,
	cardValues,
	paymentInfo,
}: {
	newOrgData: INewOrgInitialState;
	cardValues: any;
	paymentInfo: any;
}) => {
	// if cardValues equal to null then it is a token payment

	let billingDetails: any = {};
	if (newOrgData.Org_Flow_Type === 'SUB') {
		const parentOrgInMaster: any = await getDoc(
			doc(
				masterDb,
				`${process.env.REACT_APP_VERTICAL_DOMAIN}/Organizations/Organizations`,
				getOrganisationIdFirebase()
			)
		);
		const parentOrgInClient: any = await getDoc(
			doc(
				getClientDB(),
				`${getOrganisationIdFirebase()}/Organization/Organization`,
				getOrganisationIdFirebase()
			)
		);
		const parentOrgInfo: any = {
			...parentOrgInMaster.data(),
			...parentOrgInClient.data(),
		};
		billingDetails = {
			Billing_Contact_FirstName: parentOrgInfo.Billing_Contact_FirstName,
			Billing_Contact_LastName: parentOrgInfo.Billing_Contact_LastName,
			Billing_Contact_Phone: parentOrgInfo.Billing_Contact_Phone,
			Billing_Contact_Select_Phone_Type:
				parentOrgInfo.Billing_Contact_Select_Phone_Type,
			Billing_Contact_Landline_External:
				parentOrgInfo.Billing_Contact_Landline_External,
			Billing_Contact_Email: parentOrgInfo.Billing_Contact_Email,
			Billing_Contact_Email_OptIN: parentOrgInfo.Billing_Contact_Email_OptIN,
			Billing_Contact_Sms_OptIN: parentOrgInfo.Billing_Contact_Sms_OptIN,
			Billing_Contact_Voice_OptIN: parentOrgInfo.Billing_Contact_Voice_OptIN,
			City: parentOrgInfo.City,
			State: parentOrgInfo.State,
			Zip: parentOrgInfo.Zip,
			Address_Line_1: parentOrgInfo.Address_Line_1,
			Address_Line_2: parentOrgInfo.Address_Line_2,
			Billing_Contact_CountryCode: parentOrgInfo.Billing_Contact_CountryCode,
		};
	} else {
		billingDetails = {
			Billing_Contact_FirstName: cardValues.firstName,
			Billing_Contact_LastName: cardValues.lastName,
			Billing_Contact_Phone: cardValues.phoneNumber,
			Billing_Contact_Select_Phone_Type: cardValues.select_Phone_Type,
			Billing_Contact_Landline_External: cardValues.landline_ext,
			Billing_Contact_Email: cardValues.email,
			Billing_Contact_Email_OptIN: cardValues.email_optIn,
			Billing_Contact_Sms_OptIN: cardValues.sms_optIn,
			Billing_Contact_Voice_OptIN: cardValues.voice_optIn,
			City: cardValues.city,
			State: cardValues.state,
			Zip: cardValues.zip,
			Address_Line_1: cardValues.addressLine1,
			Address_Line_2: cardValues.addressLine2,
			Billing_Contact_CountryCode: cardValues.phone_countryCode,
		};
	}
	try {
		const user = JSON.parse(localStorage.getItem('user'));
		const createOrgConfigResult = await getDocs(
			query(
				collection(masterDb, 'Site_Config'),
				where('Constant', '==', 'APILISTFORDOMAIN'),
				where('Domain', '==', window.location.origin)
			)
		);
		const createOrgConfigArray = createOrgConfigResult.docs.map(
			(document: any) => ({
				id: document.id,
				...document.data(),
			})
		);
		const createOrgSiteConfig =
			createOrgConfigArray.length > 0
				? createOrgConfigArray[0].Constant_Value
				: null;
		const featuresObj = {};
		newOrgData.Org_Data?.Features_Interested.forEach((item) => {
			featuresObj[item.value] = true;
		});
		const payload = {
			Client_Org_Data: {
				...billingDetails,
				Org_Name: newOrgData.Org_Data.Org_Name,
				Country: JSON.parse(newOrgData.Org_Data.Country).value,
				Logo_Type: 'DEFAULT',
				Features_Interested: newOrgData.Org_Data.Features_Interested,
				...featuresObj,
				Country_Code: JSON.parse(newOrgData.Org_Data.Country).countryCode,
				General_Contact_CountryCode:
					JSON.parse(newOrgData.Org_Data.Country)?.countryCode || '',
				Security_Contact_CountryCode:
					JSON.parse(newOrgData.Org_Data.Country)?.countryCode || '',
				Currency: JSON.parse(newOrgData.Org_Data.Country).currency,
				Time_Format: JSON.parse(newOrgData.Org_Data.Country).timeformat,
				Date_Format: JSON.parse(newOrgData.Org_Data.Country).dateformat,
				Language: JSON.parse(newOrgData.Org_Data.Country).language,
				Time_Zone: JSON.parse(newOrgData.Org_Data.Time_Zone).value,
				Locality: newOrgData.Org_Data.User_City
					? newOrgData.Org_Data.User_City
					: '',
				User_City: newOrgData.Org_Data.User_City
					? newOrgData.Org_Data.User_City
					: '',
				User_State: newOrgData.Org_Data.User_State
					? JSON.parse(newOrgData.Org_Data.User_State).label
					: null,
				Idle_Time_Out: 300,
			},
			Org_Name: newOrgData.Org_Data.Org_Name,
			Country: JSON.parse(newOrgData.Org_Data.Country).label,
			Parent_Organization:
				newOrgData.Org_Flow_Type === 'SUB' ? getUser().organizationName : '',
			Is_Root_Parent: newOrgData.Org_Flow_Type !== 'SUB',
			Parent_Organization_Id: getOrganisationIdFirebase(),
			Account_Type: newOrgData.Org_Data.Account_Type,
			Billing_Info: {
				Contacts_Count: '',
				Price_Per_Month: '',
				Bill_Cycle: '',
				Payment_Info: paymentInfo,
			},
			Service_Status: {
				Status: 'active',
				Start_Date: '',
			},
			Vertical_Market_Domain: process.env.REACT_APP_VERTICAL_DOMAIN,
			Industry: newOrgData.Org_Data.Industry
				? JSON.parse(newOrgData.Org_Data.Industry).label
				: '',
			Sub_Domain: {
				Region: 'US_CENTRAL1',
				Name: `${newOrgData.Org_Data.Org_Name.toLowerCase()}.${
					process.env.REACT_APP_VERTICAL_DOMAIN
				}`,
				Domain: process.env.REACT_APP_VERTICAL_DOMAIN,
				Country: JSON.parse(newOrgData.Org_Data.Country).value,
			},
			User: {
				Email: user.email,
				FirstName: user.fName,
				LastName: user.lName,
				Name: `${user.fName} ${user.lName}`,
				Provider: '',
				Password: '',
				User_Id: user.userId,
			},
			Created_By_User_Id: user.userId,
			Created_Date_Time_Gmt: moment(),
			Last_Modified_By_User_Id: user.Id,
			Last_Modified_Date_Time_Gmt: moment(),
		};
		const instance = createWithXProjKey();
		const result = await instance.post(
			`${createOrgSiteConfig.REACT_APP_CREATE_ORGANIZATION_API}/createMultiTenant`,
			payload
		);

		return result;
	} catch (error) {
		return error;
	}
};

export const CreateSubOrganization = async (orgname: any) => {
	try {
		const parentOrg: any = await getDoc(
			doc(
				masterDb,
				`${process.env.REACT_APP_VERTICAL_DOMAIN}/Organizations/Organizations`,
				getOrganisationIdFirebase()
			)
		);
		const parentOrgInfo: any = parentOrg.data();
		const userInfo = JSON.parse(localStorage.getItem('user'));

		const payload = {
			Org_Name: orgname,
			Country: parentOrgInfo.Country,
			Parent_Organization: parentOrgInfo.Org_Name,
			Parent_Organization_Id: getOrganisationIdFirebase(),
			Is_Root_Parent: false,
			Account_Type: parentOrgInfo.Account_Type,
			Billing_Info: parentOrgInfo.Billing_Info,
			Service_Status: {
				Status: 'active',
				Start_Date: '',
			},
			Storage_Folder_Access: {
				Json_File_Name: 'playplacecrm-dev-us-storage.json',
				Project_Id: 'playplacecrm-dev-us',
			},
			Vertical_Market_Domain: parentOrgInfo.Vertical_Market_Domain,
			Sub_Domain: parentOrgInfo.Sub_Domain,
			User: {
				Email: userInfo.email,
				FirstName: userInfo.fName,
				LastName: userInfo.lName,
				Name: `${userInfo.fName} ${userInfo.lName}`,
				Provider: '',
				Password: '',
				User_Id: userInfo.userId,
			},
			Created_By_User_Id: parentOrgInfo.userId,
			Created_Date_Time_Gmt: moment(),
			Last_Modified_By_User_Id: parentOrgInfo.Id,
			Last_Modified_Date_Time_Gmt: moment(),
		};

		const instance = createWithXProjKey();

		const result = await instance.post(
			`${cloudBaseUrl()}/organizations/create`,
			payload
		);
		return result;
	} catch (error) {
		return error;
	}
};

export const GetOrganisationOfUser = async (userid: any) => {
	try {
		const qry = query(
			collection(masterDb, 'UsersMapping'),
			where('User_Id', '==', userid)
		);
		const resultSnapshots = await getDocs(qry);
		const resultArray = resultSnapshots.docs.map((document) => ({
			...document.data(),
			id: document.id,
		}));
		resultArray.sort((a: any, b: any) => a.Org_Name.localeCompare(b.Org_Name));
		return resultArray;
	} catch (error) {
		return error;
	}
};

export const GetOrganisationInfoById = async (
	organisationId: any,
	createUser = false
) => {
	try {
		// org info from masterdb
		const orgInMasterDB: any = await getDoc(
			doc(
				masterDb,
				`${process.env.REACT_APP_VERTICAL_DOMAIN}/Organizations/Organizations`,
				organisationId
			)
		);
		clientApp = firebase.initializeApp(
			orgInMasterDB.data().Firestore_Config,
			organisationId
		);

		const clientDB = clientApp.firestore();
		const user = getUser();
		const clientAuth = getAuth(clientApp);

		const payload = {
			Have_To_Create_User: createUser,
			Email: user.email,
			Custom_Token_For: 'client',
			UID: masterAuth?.currentUser?.uid || user.uid,
		};

		const authUrl =
			orgInMasterDB.data().Constant_Value.REACT_APP_MISCELLANEOUS_URL;
		const clientResult = await getCustomAccessToken(payload, authUrl);
		const accessToken = clientResult.data.custom_user_token;
		const authenticateUser: any = await signInWithCustomToken(
			clientAuth,
			accessToken
		);
		let masterAuthenticatedUser = masterAuth?.currentUser;
		if (authenticateUser) {
			const payloadObj = {
				Have_To_Create_User: false,
				Email: user.email,
				Custom_Token_For: 'master',
				UID: authenticateUser.user.uid,
			};
			const masterResult: any = await getCustomAccessToken(payloadObj, authUrl);
			if (masterResult) {
				// sign in again to make sure just in case if the master token is expired while user is in the choose org page for long time
				masterAuthenticatedUser = (
					await signInWithCustomToken(
						masterAuth,
						masterResult.data.custom_user_token
					)
				).user;
			}
			// org info from client db
			const qry = query(
				collection(clientDB, `${organisationId}/Organization/Organization`)
			);
			const resultSnapshots = await getDocs(qry);
			const resultArray = resultSnapshots.docs.map((document) => ({
				...document.data(),
				id: document.id,
			}));

			// converting new org info to match with existing structure in localStorage
			const orgInClientDB: any = resultArray[0];

			const masterSiteConfigResult = await getDocs(
				query(
					collection(masterDb, 'Site_Config'),
					where('Constant', '==', 'APILISTFORDOMAIN'),
					where('Domain', '==', window.location.origin)
				)
			);
			const masterSiteConfigOrgConfigArray = masterSiteConfigResult.docs.map(
				(document: any) => ({
					id: document.id,
					...document.data(),
				})
			);
			const masterSiteConfig =
				masterSiteConfigOrgConfigArray.length > 0
					? masterSiteConfigOrgConfigArray[0]?.Constant_Value
					: '';

			const convertedOrgInfo = {
				CurrencyDetails: {
					Country: orgInClientDB.Currency,
					CurrencyCharacterCode: orgInClientDB.Currency,
					CurrencyCode: orgInClientDB.Currency,
					CurrencyName: orgInClientDB.Currency,
				},
				OtherConfig: {
					FormatDate: orgInClientDB.Date_Format,
					FormatTime: orgInClientDB.Time_Format,
					TimeZone: orgInClientDB.Time_Zone,
				},
				filePath: {
					...orgInMasterDB.data().Folder_Path,
					client_website_url: 'https://storage.googleapis.com/peeknplay_dev_website',
					clientwebsitefilepath: 'peeknplay_dev_website',
				},
				organizationPath: '1617647525093',
				organisationPathFirebase: organisationId,
				firestoreConfig: orgInMasterDB.data().Firestore_Config,
				organizationName: orgInClientDB.Org_Name,
				accountType: orgInMasterDB.data().Account_Type,
				subDomain: orgInMasterDB.data().Sub_Domain,
				verticalMarketDomain: orgInMasterDB.data().Vertical_Market_Domain,
				isParentOrganisation: orgInMasterDB.data().Is_Root_Parent,
				parentOrganizationName: orgInMasterDB.data().Parent_Organization,
				parentOrganizationId: orgInMasterDB.data().Parent_Organization_Id,
				idleTimeOut: orgInClientDB?.Idle_Time_Out || 300,
				masterToken: masterAuthenticatedUser?.accessToken || user?.masterToken,
				uid: masterAuthenticatedUser?.uid || user?.uid,
			};

			const siteConfig = {
				...masterSiteConfig,
				...orgInMasterDB.data().Constant_Value,
			};
			// keeping the existing user data as it is
			const { fName, lName, email, userId } = JSON.parse(
				localStorage.getItem('user')
			);

			const finalLocalStorageObj = {
				fName,
				lName,
				email,
				userId,
				...convertedOrgInfo,
			};
			localStorage.setItem('user', JSON.stringify(finalLocalStorageObj));
			localStorage.setItem('siteConfig', JSON.stringify(siteConfig));
			return finalLocalStorageObj;
		}
		return null;
	} catch (error) {
		return error;
	}
};

export const getSiteConfig = async (organizationId) => {
	try {
		const parentOrgInMasterDB: any = await getDoc(
			doc(
				masterDb,
				`${process.env.REACT_APP_VERTICAL_DOMAIN}/Organizations/Organizations`,
				organizationId
			)
		);
		return parentOrgInMasterDB.data();
	} catch (e) {
		return null;
	}
};

export const getMasterSiteConfig = async (url) => {
	try {
		const siteConfigList = await getDocs(
			query(
				collection(masterDb, 'Site_Config'),
				where('Constant', '==', 'APILISTFORDOMAIN'),
				where('Domain', '==', url)
			)
		);
		const resultArray = siteConfigList.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));
		return resultArray.length > 0 ? resultArray[0] : null;
	} catch (e) {
		return null;
	}
};
export const setEmailBuilderAssetsFromStorage = async (url) => {
	try {
		const siteConfigList = await getDocs(
			query(
				collection(masterDb, 'Site_Config'),
				where('Constant', '==', 'EMAILBUILDERASSSETS'),
				where('Domain', '==', url)
			)
		);
		const resultArray = siteConfigList.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));
		return resultArray.length > 0 ? resultArray[0] : null;
	} catch (e) {
		return null;
	}
};

export const getEmailBuilderAssetsFromStorage = () => {
	const emailBuilderBucket = localStorage.getItem('emailBuilderAssetsBucket');
	try {
		if (JSON.parse(emailBuilderBucket)) {
			return JSON.parse(emailBuilderBucket)?.bucket;
		}
	} catch (error) {
		return null;
	}
	return null;
};

export const setWebsiteBuilderAssetsFromStorage = async (url) => {
	try {
		const siteConfigList = await getDocs(
			query(
				collection(masterDb, 'Site_Config'),
				where('Constant', '==', 'WEBSITEBUILDERASSSETS'),
				where('Domain', '==', url)
			)
		);
		const resultArray = siteConfigList.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));
		return resultArray.length > 0 ? resultArray[0] : null;
	} catch (e) {
		return null;
	}
};

export const getWebsiteBuilderAssetsFromStorage = () => {
	const emailBuilderBucket = localStorage.getItem('websiteBuilderAssetsBucket');
	try {
		if (JSON.parse(emailBuilderBucket)) {
			return JSON.parse(emailBuilderBucket)?.bucket;
		}
	} catch (error) {
		return null;
	}
	return null;
};

export const UpdateSubOrgPaymentStatus = async (data) => {
	const taskDocRef = doc(
		masterDb,
		`${process.env.REACT_APP_VERTICAL_DOMAIN}/Organizations/Organizations/${data.id}`
	);
	try {
		const result = await updateDoc(taskDocRef, {
			Billing_Info: data.paymentResponse,
		});
		return result;
	} catch (err) {
		return err;
	}
};
export const MakeVerifonePayment = async ({
	Verifone_Card_Token,
	cardValues,
	newOrgData,
}: {
	Verifone_Card_Token: string;
	cardValues: any;
	newOrgData: any;
}) => {
	try {
		const paymentConfigResult = await getDocs(
			query(
				collection(masterDb, 'Site_Config'),
				where('Constant', '==', 'APILISTFORDOMAIN'),
				where('Domain', '==', window.location.origin)
			)
		);
		const paymentConfigArray = paymentConfigResult.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));
		const paymentSiteconfig =
			paymentConfigArray.length > 0 ? paymentConfigArray[0].Constant_Value : null;
		const payload = {
			amount: 0.0,
			customer_ip: '127.0.0.1',
			shipping_information: {
				address: `${cardValues.addressLine1} ${cardValues.addressLine2}`,
				city: cardValues.city,
				phone: Number(cardValues.phoneNumber),
				country_code: JSON.parse(newOrgData.Org_Data.Country).countryCode,
				postal_code: cardValues.zip,
				state: cardValues.state,
				country: JSON.parse(newOrgData.Org_Data.Country).value,
			},
			currency_code: 'USD',
			card_brand: cardValues.issuer.toUpperCase(),
			encrypted_card: Verifone_Card_Token,
		};
		const instance = createWithXProjKey();
		const result = await instance.post(
			`${paymentSiteconfig.REACT_APP_VERIFONE_PAYMENT_API}/charge?Env=${process.env.REACT_APP_PAYMENT_ENV}&requestfor=ECOM&project_id=${process.env.REACT_APP_FIREBASE_MASTER_PROJECT_ID}`,
			payload
		);
		return result.data;
	} catch (error) {
		return error;
	}
};

export const MakeVerifonePaymentWithToken = async () => {
	const parentOrgInMasterDB: any = await getDoc(
		doc(
			masterDb,
			`${process.env.REACT_APP_VERTICAL_DOMAIN}/Organizations/Organizations`,
			getOrganisationIdFirebase()
		)
	);
	try {
		const payload = {
			amount: 0.0,
			customer_ip: '127.0.0.1',
			shipping_information:
				parentOrgInMasterDB.data()?.Billing_Info?.Payment_Info
					?.shipping_information,
			currency_code: 'USD',
			card_brand:
				parentOrgInMasterDB.data()?.Billing_Info?.Payment_Info?.token_details
					?.brand,
			reuse_token:
				parentOrgInMasterDB.data()?.Billing_Info?.Payment_Info?.token_details
					?.reuse_token,
		};
		const instance = createWithXProjKey();
		const result = await instance.post(
			`${siteConfigConstants().REACT_APP_VERIFONE_PAYMENT_API}/reuseToken?Env=${
				process.env.REACT_APP_PAYMENT_ENV
			}&requestfor=ECOM&project_id=${
				process.env.REACT_APP_FIREBASE_MASTER_PROJECT_ID
			}`,
			payload
		);
		return {
			...result.data,
			reused_token: payload.reuse_token,
		};
	} catch (error) {
		return error;
	}
};

export const refreshMasterToken = (user): any =>
	new Promise((resolve) => {
		const masterAuth2: any = getAuth(masterapp);

		onAuthStateChanged(masterAuth2, async (usr: any) => {
			if (usr) {
				const updatedUsr = { ...user, masterToken: usr.accessToken, uid: usr.uid };
				localStorage.setItem('user', JSON.stringify(updatedUsr));
				resolve(usr);
			} else {
				// renew the token
				const payload = {
					Have_To_Create_User: false,
					UID: user.uid,
					Custom_Token_For: 'master',
				};
				const customAccessTokenResultMaster: any = await getCustomAccessToken(
					payload
				);
				const accessTokenMaster =
					customAccessTokenResultMaster.data.custom_user_token;
				await signInWithCustomToken(masterAuth, accessTokenMaster);

				resolve(true);
			}
		});
	});

export const refreshClientToken = (user): any =>
	new Promise((resolve) => {
		if (!clientApp) {
			clientApp = firebase.initializeApp(
				user.firestoreConfig,
				user.organisationPathFirebase
			);
		}

		const clientAuth = getAuth(clientApp);
		onAuthStateChanged(clientAuth, async (usr: any) => {
			if (usr) {
				const updatedUsr = { ...user, token: usr.accessToken, uid: usr.uid };
				localStorage.setItem('user', JSON.stringify(updatedUsr));
				resolve(updatedUsr);
			} else {
				// Refresh master token again as master is not re authenticating automatically
				const payload = {
					Have_To_Create_User: false,
					Email: user.email,
					UID: user.uid,
					Custom_Token_For: 'client',
				};
				const customAccessTokenResultMaster: any = await getCustomAccessToken(
					payload
				);
				const accessTokenMaster =
					customAccessTokenResultMaster.data.custom_user_token;
				await signInWithCustomToken(clientAuth, accessTokenMaster);
				resolve(true);
			}
		});
	});
