export const TOGGLE_COLLAPSE = 'TOGGLE_COLLAPSE';
export const TOGGLE_HEADER_NAV = 'TOGGLE_HEADER_NAV';
export const RELOAD_LAYOUT = 'RELOAD_LAYOUT';

export interface TOGGLE_COLLAPSE {
	type: typeof TOGGLE_COLLAPSE;
}

export interface TOGGLE_HEADER_NAV {
	type: typeof TOGGLE_HEADER_NAV;
	payload: boolean;
}
export interface RELOAD_LAYOUT {
	type: typeof RELOAD_LAYOUT;
	payload: boolean;
}

export type LayoutDispatchTypes =
	| TOGGLE_COLLAPSE
	| TOGGLE_HEADER_NAV
	| RELOAD_LAYOUT;
