export const SET_SMS_MAIN_MESSAGE = 'SET_SMS_MAIN_MESSAGE';
export const SET_SMS_MAIN_MESSAGE_HAVE_DYNAMIC =
	'SET_SMS_MAIN_MESSAGE_HAVE_DYNAMIC';
export const SET_SMS_VIEW = 'SET_SMS_VIEW';
export const RESET_SMS_CREATIVE = 'RESET_SMS_CREATIVE';
export const SET_SMS_DYNAMIC_VARIABLES = 'SET_SMS_DYNAMIC_VARIABLES';
export const SET_SMS_REPLY = 'SET_SMS_REPLY';
export const SET_SMS_RESPONSE = 'SET_SMS_RESPONSE';
export const SET_SMS_DISPLAY_MESSAGE = 'SET_SMS_DISPLAY_MESSAGE';
export const SET_SMS_MEDIA_URLS = 'SET_SMS_MEDIA_URLS';
export const SET_SMS_MAIN_MEDIA_URLS = 'SET_SMS_MAIN_MEDIA_URLS';
export const ADD_SMS_REPLY = 'ADD_SMS_REPLY';
export const SET_SMS_NODE_ID = 'SET_SMS_NODE_ID';
export const SET_SMS_TREE_DATA = 'SET_SMS_TREE_DATA';
export const SET_SMS_SELECTED_CATEGORY = 'SET_SMS_SELECTED_CATEGORY';
export const SET_SMS_SELECTED_SUBCATEGORY = 'SET_SMS_SELECTED_SUBCATEGORY';
export const SET_SMS_CATEGORIES = 'SET_SMS_CATEGORIES';
export const SET_SMS_CREATIVE_NAME = 'SET_SMS_CREATIVE_NAME';
