import React, { useEffect, useState } from 'react';
import { clear, getUser } from 'api/AxiosManager';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'components/Spinner';
import WelcomeLinearProgress from 'components/Welcome-comp/Welcome-progress-bar';
import { RootStore } from 'reducers';
import { showLoading } from 'reducers/Alerts/AlertActions';
import Header from './components/Header';

const routes = ['/login2factorauthentication', '/signup2FA'];

export function Protected({ component: Component, ...props }: any) {
	const history = useHistory();
	const user = getUser();
	const { loading } = useSelector((state: RootStore) => state.alertsReducer);
	const layoutStore = useSelector((state: RootStore) => state.layoutReducer);
	const { progressVal } = useSelector(
		(state: RootStore) => state.welcomeProgressBarReducer
	);
	const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

	const dispatch = useDispatch();
	const logout = () => {
		clear();
		dispatch(showLoading());
		history.push('/');
	};

	const route = history.location.pathname;
	function checkUserAndOrgIsValidOrNot() {
		if (user?.isGoogleAuthEnabled || user?.isSMSAuthEnabled) {
			history.push('/login2factorauthentication');
		}
		if (user.firstLogin) {
			history.push('/signup2FA');
		}
		if (Object.keys(user ?? null).length === 0 || !user?.userId) {
			logout();
		}
	}

	useEffect(() => {
		checkUserAndOrgIsValidOrNot();
	}, []);

	return (
		<>
			{loading && <Spinner />}
			<div className='flex flex-col h-full'>
				<Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
				{progressVal > 0 && (
					<WelcomeLinearProgress
						color='primary'
						delay={300}
						value={progressVal}
						showBackDrop={false}
						message=''
					/>
				)}
				<div
					className={`flex bg-gray-lightest h-full ${
						layoutStore.HeaderNav && user ? '' : 'mt-2'
					}`}
				>
					<div className='block md:flex flex-col pt-4 flex-1 w-full fullscreenwithheader overflow-auto'>
						<main className='flex-1 relative z-0 focus:outline-none'>
							<div className='w-full mx-auto px-4 sm:px-6 md:px-8 h-full'>
								{(!(
									user?.isGoogleAuthEnabled ||
									user?.isSMSAuthEnabled ||
									user?.firstLogin
								) ||
									routes.includes(route)) && <Component {...props} />}
							</div>
						</main>
					</div>
				</div>
			</div>
		</>
	);
}

export default Protected;
