import { create2WayAuth } from "./AxiosManager";

export const generateQRCode = async (payload) => {
    try {
        const instance = create2WayAuth();
        const result = await instance.post(`/generateAuthQrCode`, payload);
        return result.data;
    }
    catch (e) {
        return null;
    }
}

export const verifyAuthenticatorOTP = async (payload) => {
    try {
        const instance = create2WayAuth();
        const result = await instance.post(`/verifyGoogleAuthenticator`, payload);
        return result.data;
    }
    catch (e) {
        return null;
    }
}

export const sendPhoneNumber = async (payload) => {
    try {
        const instance = create2WayAuth();
        const result = await instance.post(`/sendVerifyPhoneNumberToken`, payload);
        return result.data;
    }
    catch (e) {
        return null;
    }
}

export const sendSMSAuthCode = async (payload) => {
    try {
        const instance = create2WayAuth();
        const result = await instance.post(`/sendSMSAuthToken`, payload);
        return result.data;
    }
    catch (e) {
        return null;
    }
}

export const verifyPhoneAuth = async (payload) => {
    try {
        const instance = create2WayAuth();
        const result = await instance.post(`/verifySMSAuthToken`, payload);
        return result.data;
    }
    catch (e) {
        return null;
    }
}