import React from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import NoDataIcon from 'assets/img/no-data.svg';
import 'styles/MUIDataGrid.scss';

export default function MUIGridGloabl({
	columns,
	dataSource,
	rowCount,
	initialState,
	onRowClick,
	rowSelection,
	onSelectionModelChange,
	selectionModal,
	setPagination = false,
	paginationMode = 'server',
	headerHeight = 45,
	gridHeight = '600px',
	Toolbar = true,
	noRecordsText = '',
	noRecordsCta,
	columnVisibilityModel,
	onColumnVisibilityModelChange,
	loading = false,
	sxStyle,
	hideFooter = false,
	isRowSelectable,
}: IMUIGloabGrid) {
	return (
		<div style={{ height: gridHeight }} className='w-full bg-white shadow-md'>
			<DataGridPro
				sx={{ ...sxStyle, boxShadow: 2 }}
				hideFooter={hideFooter}
				headerHeight={headerHeight}
				rows={dataSource}
				columns={columns}
				rowCount={rowCount}
				pagination={setPagination}
				checkboxSelection={rowSelection}
				paginationMode={paginationMode}
				components={{
					Toolbar: Toolbar && GridToolbar,
					NoRowsOverlay: () => (
						<div className='flex flex-col justify-center items-center gap-3 h-full w-full absolute mx-auto z-50'>
							<img src={NoDataIcon} className='w-14 h-14' />
							<span className='font-semibold text-xl text-gray-600 capitalize'>
								{noRecordsText}
							</span>
							{noRecordsCta}
						</div>
					),
					NoResultsOverlay: () => (
						<div className='flex flex-col justify-center items-center gap-3 h-full w-full absolute mx-auto z-50'>
							<img src={NoDataIcon} className='w-14 h-14' />
							<span className='font-semibold text-xl text-gray-600 capitalize'>
								{noRecordsText}
							</span>
							{noRecordsCta}
						</div>
					),
				}}
				onRowClick={onRowClick}
				loading={loading}
				initialState={initialState}
				onSelectionModelChange={onSelectionModelChange}
				selectionModel={selectionModal}
				columnVisibilityModel={columnVisibilityModel}
				onColumnVisibilityModelChange={onColumnVisibilityModelChange}
				isRowSelectable={isRowSelectable}
			/>
		</div>
	);
}

export interface IMUIGloabGrid {
	columns: any;
	dataSource: [];
	rowCount: number;
	initialState?: any;
	sxStyle?: any;
	hideFooter?: boolean;
	onRowClick?: any;
	rowSelection?: any;
	onSelectionModelChange?: any;
	selectionModal?: any;
	setPagination?: boolean;
	paginationMode?: 'server' | 'client';
	headerHeight?: any;
	gridHeight?: any;
	Toolbar?: boolean;
	onAddButtonTitleClick?: any;
	addButtonTitle?: string;
	noRecordsText?: string;
	noRecordsCta?: any;
	onColumnVisibilityModelChange?: any;
	columnVisibilityModel?: any;
	loading?: boolean;
	isRowSelectable?: any;
}
