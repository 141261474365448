import React from 'react';
import TextField from '@mui/material/TextField';
import InputMask from 'react-input-mask';

interface IPhoneNumber {
	mask: string;
	phoneValue: string;
	setPhoneValue: (val) => void;
}
const PhoneNumber = ({ mask, phoneValue, setPhoneValue }: IPhoneNumber) => {
	const phoneValueChange = (e) => {
		setPhoneValue(e.target.value.replace(/[^\d]/g, ''));
	};
	return (
		<InputMask
			mask={mask || '999-999-9999'}
			value={phoneValue}
			onChange={phoneValueChange}
			maskChar=' '
			alwaysShowMask
		>
			{() => (
				<TextField
					type='text'
					label='Phone Number'
					variant='outlined'
					size='small'
					sx={{ width: '100%' }}
				/>
			)}
		</InputMask>
	);
};

export default PhoneNumber;
