import { getUser, clear } from 'api/AxiosManager';
import { GetOrganisationDetailsById } from 'firebaseApis/organization';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

export function DomainRoute({ component: Component, ...props }: any) {
	const history = useHistory();
	const user = getUser();

	async function checkUserAndOrgIsValidOrNot() {
		if (Object.keys(user).length === 0) {
			clear();
			return history.push('/');
		}
		if (
			Object.keys(user).length &&
			user?.organisationPathFirebase === undefined
		) {
			return history.push('/chooseorg');
		}

		if (
			Object.keys(user).length &&
			user?.organisationPathFirebase !== undefined
		) {
			try {
				const response = await GetOrganisationDetailsById(
					user.organisationPathFirebase
				);
				if (Object.keys(response).length === 0) {
					return history.push('/chooseorg');
				}
			} catch (error) {
				return history.push('/chooseorg');
			}
		}

		return null;
	}

	useEffect(() => {
		checkUserAndOrgIsValidOrNot();
	}, [history.location]);

	return <Component {...props} />;
}

export default DomainRoute;
