import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseMasterConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_MASTER_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_MASTER_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_MASTER_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_MASTER_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MASTER_MESSAGING_SENDERID,
	appId: process.env.REACT_APP_FIREBASE_MASTER_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MASTER_MEASUREMENT_ID,
};

// Initialize Firebase
const masterapp = firebase.initializeApp(firebaseMasterConfig, 'master');
const masterDb = masterapp.firestore();

export { masterDb, masterapp , firebaseMasterConfig };
