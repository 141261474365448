import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import CryptoJS from 'crypto-js';
import Toast, { ToastTypes } from 'components/ToastNotify';
import Divider from '@mui/material/Divider';
import Button from 'storybook-mui/build/components/Button';
import {
	GetOrganisationInfoById,
	GetOrganisationOfUser,
} from 'firebaseApis/authentication';

import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import { clear, getUser, siteConfigConstants } from 'api/AxiosManager';
import { increaseProgressVal } from 'reducers/WelcomeProgressbar';
import LinearProgressBar from 'components/LinearProgressBar';
import { delay } from 'helpers/utils';

function ChooseOrg() {
	const [orgData, setOrgData] = useState<[]>([]);
	const [readyToRender, setReadyToRender] = useState(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [currentProgress, setCurrentProgress] = useState<number>(0);
	const history = useHistory();
	const dispatch = useDispatch();

	const logOut = () => {
		clear();
		history.push('/');
	};
	const selectOrganisation = async (id: any) => {
		try {
			setLoading(true);
			const response = await GetOrganisationInfoById(id);
			setCurrentProgress(100);
			await delay(300);
			setLoading(false);

			if (response.organizationName && response.organisationPathFirebase) {
				const token = {
					user: response,
				};
				const encryptedToken = CryptoJS.AES.encrypt(
					JSON.stringify(token),
					'LEAP360'
				).toString();
				if (siteConfigConstants().ORGANIZATION_ASSOCIATED_WITH_DOMAIN) {
					let url = `${
						siteConfigConstants().ORGANIZATION_ASSOCIATED_WITH_DOMAIN
					}/redirectFromLeap360?authenticationToken=${encryptedToken}`;
					if (window.location.hostname === 'dev.leap360.com') {
						url = `https://dev.leap360.com:3000/redirectFromLeap360?authenticationToken=${encryptedToken}`;
					}
					window.location.replace(url);
				} else {
					window.location.replace(
						`${siteConfigConstants().REACT_APP_LOGOUT_REDIRECT_URL}/createOrg`
					);
				}
			} else {
				throw new Error('Something went wrong');
			}
		} catch (error) {
			Toast({
				title: 'Session expired. Please login back.',
				type: ToastTypes.ERROR,
			});
			logOut();
			setLoading(false);
		}
	};
	const getData = async () => {
		try {
			const user = getUser();
			dispatch(showLoading());
			const resultData = await GetOrganisationOfUser(user.userId);
			dispatch(hideLoading());
			if (resultData.length === 1) {
				selectOrganisation(resultData[0].Organization_Id);
				setReadyToRender(true);
			} else {
				setOrgData(resultData);
				setReadyToRender(true);
			}
		} catch (error) {
			dispatch(hideLoading());
			Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
		}
	};
	useEffect(() => {
		getData();
		dispatch(increaseProgressVal(0));
	}, []);
	return (
		<div className='flex flex-col mt-16'>
			{loading && (
				<LinearProgressBar
					delay={8000}
					incrementBy={1}
					currentProgress={currentProgress}
					message='Loading organization...'
				/>
			)}
			<div className='flex flex-col gap-8 mt-10 justify-center items-center'>
				<Button
					title='Create a new organization'
					onClick={() => history.push('/createorg')}
					size='large'
				/>
				{orgData?.length !== 0 && readyToRender && (
					<>
						<div className='w-full lg:w-1/2'>
							<Divider>
								<p>Select one from below</p>
							</Divider>
						</div>

						<div className='flex flex-col'>
							<div className='flex justify-center'>
								<div className='grid justify-center md:grid-cols-2 grid-cols-1 gap-6'>
									{orgData?.map((org: any, index) => (
										<div
											key={index}
											className='px-6 py-2 rounded-md border-gray-300 hover:border-primary-600 border cursor-pointer w-86 bg-white hover:shadow-inner'
											onClick={() => selectOrganisation(org.Organization_Id)}
										>
											<h1 className='text-lg font-bold text-gray-500 text-center hover:text-primary-600'>
												{org.Org_Name}
											</h1>
										</div>
									))}
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
}

export default ChooseOrg;
