import { SET_COMPANY_LOGIN_STORE } from './CompanyLoginActionsTypes';

interface ICreative {
	data: {};
}

const initialState: ICreative = {
	data: {},
};

const CompanyLogin = (state: any = initialState, action) => {
	switch (action.type) {
		case SET_COMPANY_LOGIN_STORE:
			return {
				...state,
				data: action.payload,
			};

		default:
			return state;
	}
};

export default CompanyLogin;
