import {
	RESET_SMS_CREATIVE,
	SET_SMS_DYNAMIC_VARIABLES,
	SET_SMS_MAIN_MESSAGE,
	SET_SMS_VIEW,
	SET_SMS_REPLY,
	SET_SMS_RESPONSE,
	ADD_SMS_REPLY,
	SET_SMS_TREE_DATA,
	SET_SMS_SELECTED_CATEGORY,
	SET_SMS_SELECTED_SUBCATEGORY,
	SET_SMS_CATEGORIES,
	SET_SMS_CREATIVE_NAME,
	SET_SMS_NODE_ID,
	SET_SMS_DISPLAY_MESSAGE,
	SET_SMS_MEDIA_URLS,
	SET_SMS_MAIN_MEDIA_URLS,
	SET_SMS_MAIN_MESSAGE_HAVE_DYNAMIC,
} from './SMSCreativesActionTypes';

interface ISMSCreative {
	mainMessage: string;
	mainMessageHaveDynamicVariable: boolean;
	view: number;
	dynamicVariables: [];
	smsReply: string;
	smsResponse: string;
	smsReplies: [];
	smsTreeData: any;
	selectedCategory: string;
	selectedSubCategory: string;
	categories: [];
	creativeName: string;
	id: string;
	currentId: string;
	displayMessage: string;
	smsMediaUrls: [];
	smsMainMediaUrls: [];
}

const initialState: ISMSCreative = {
	mainMessage: '',
	mainMessageHaveDynamicVariable: false,
	displayMessage: '',
	view: 1,
	dynamicVariables: [],
	smsReply: '',
	smsResponse: '',
	smsReplies: [],
	smsTreeData: { children: [] },
	selectedCategory: '',
	selectedSubCategory: '',
	categories: [],
	creativeName: '',
	id: '',
	currentId: '',
	smsMediaUrls: [],
	smsMainMediaUrls: [],
};
const CreativesReducer = (state: any = initialState, action) => {
	switch (action.type) {
		case SET_SMS_MAIN_MESSAGE:
			return { ...state, mainMessage: action.payload };

		case SET_SMS_MAIN_MESSAGE_HAVE_DYNAMIC:
			return { ...state, mainMessageHaveDynamicVariable: action.payload };

		case SET_SMS_DISPLAY_MESSAGE:
			return { ...state, displayMessage: action.payload };
		case SET_SMS_VIEW:
			return { ...state, view: action.payload };
		case SET_SMS_DYNAMIC_VARIABLES:
			return { ...state, dynamicVariables: action.payload };
		case SET_SMS_REPLY:
			return { ...state, smsReply: action.payload };
		case SET_SMS_RESPONSE:
			return { ...state, smsResponse: action.payload };
		case ADD_SMS_REPLY:
			return { ...state, smsReplies: action.payload };
		case SET_SMS_TREE_DATA:
			return { ...state, smsTreeData: action.payload };
		case SET_SMS_MEDIA_URLS:
			return { ...state, smsMediaUrls: action.payload };
		case SET_SMS_MAIN_MEDIA_URLS:
			return { ...state, smsMainMediaUrls: action.payload };
		case SET_SMS_SELECTED_CATEGORY:
			return { ...state, selectedCategory: action.payload };
		case SET_SMS_SELECTED_SUBCATEGORY:
			return { ...state, selectedSubCategory: action.payload };

		case SET_SMS_CATEGORIES:
			return { ...state, categories: action.payload };
		case SET_SMS_CREATIVE_NAME:
			return { ...state, creativeName: action.payload };
		case SET_SMS_NODE_ID:
			return { ...state, currentId: action.payload };
		case RESET_SMS_CREATIVE:
			return {
				mainMessage: '',
				displayMessage: '',
				mainMessageHaveDynamicVariable: false,
				view: 1,
				dynamicVariables: [],
				smsReply: '',
				smsResponse: '',
				smsReplies: [],
				smsTreeData: { children: [] },
				selectedCategory: '',
				categories: [],
				creativeName: '',
				id: '',
				currentId: '',
				smsMediaUrls: [],
				smsMainMediaUrls: [],
			};
		default:
			return state;
	}
};

export default CreativesReducer;
