import { GridColDef } from '@mui/x-data-grid-pro';
import MUIGridGloabl from 'components/Table/MUIGlobalGrid';
import Toast, { ToastTypes } from 'components/ToastNotify';
import { DeletePosCoupon, GetPosCoupons } from 'firebaseApis/pos-coupons';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import Button from 'storybook-mui/build/components/Button';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';
import Modal from 'storybook-mui/build/components/Modal';
import TypographyComponent from 'storybook-mui/build/components/Typography';
import AddCoupon from './AddEdit';

function Coupons() {
	const [showAddEditModal, setShowAddEditModal] = React.useState(false);
	const [teamMembers, setTeamMembers] = React.useState<[]>([]);
	const [modalType, setModalType] = React.useState<'add' | 'edit'>('add');
	const [selectedCoupon, setSelectedCoupon] = React.useState<any>(null);
	const [showDeleteModal, setShowDeleteModal] = React.useState(false);
	const dispatch = useDispatch();
	const getData = async () => {
		try {
			dispatch(showLoading());
			const response = await GetPosCoupons();
			if (response.success) {
				setTeamMembers(response.data);
			}
			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
			Toast({ title: error.message, type: ToastTypes.ERROR });
		}
	};

	useEffect(() => {
		// fetch team members
		getData();
	}, []);

	const deleteCoupon = async () => {
		try {
			dispatch(showLoading());
			const response = await DeletePosCoupon(selectedCoupon.id);
			if (response.success) {
				Toast({ title: 'Coupon Deleted', type: ToastTypes.SUCCESS });
				getData();
				setShowDeleteModal(false);
			}
			dispatch(hideLoading());
		} catch (error) {
			Toast({ title: error.message, type: ToastTypes.ERROR });
		}
	};

	const columns: GridColDef[] = [
		{
			headerName: 'Coupon Code',
			field: 'Coupon_Code',
			flex: 1,
		},
		{
			headerName: 'Discount_Type',
			field: 'Discount_Type',
			flex: 1,
		},
		{
			headerName: 'Discount_Amount',
			field: 'Discount_Amount',
			flex: 1,
		},
		{
			headerName: 'Discount_Percentage',
			field: 'Discount_Percentage',
			flex: 1,
		},
		{
			headerName: 'Status',
			field: 'Is_Active',
			flex: 1,
			renderCell: (data: any) => <>{data.row.Is_Active ? 'Active' : 'InActive'}</>,
		},
		{
			headerName: 'Actions',
			field: 'Actions',
			minWidth: 200,
			renderCell: (data: any) => (
				<div className='flex items-center gap-5'>
					<Icon
						icon={IconTypes.Delete}
						className='cursor-pointer'
						onClick={() => {
							setSelectedCoupon(data.row);
							setShowDeleteModal(true);
						}}
					/>
					<Icon
						icon={IconTypes.Edit}
						className='cursor-pointer'
						onClick={() => {
							setSelectedCoupon(data.row);
							setModalType('edit');
							setShowAddEditModal(true);
						}}
					/>
				</div>
			),
		},
	];
	return (
		<>
			<div className='my-2 flex items-center justify-between  w-full'>
				<TypographyComponent
					label='Coupons'
					id='Payment Terminals'
					variant='h4'
					sx={{
						fontSize: {
							lg: 30,
							xs: 20,
						},
					}}
				/>
				<Button
					title='Add Coupon'
					onClick={() => {
						setModalType('add');
						setShowAddEditModal(true);
					}}
					variant='contained'
					color='primary'
				/>
			</div>

			<MUIGridGloabl
				columns={columns}
				dataSource={teamMembers}
				rowCount={teamMembers.length}
			/>
			{showAddEditModal && (
				<AddCoupon
					showAddEditModal={showAddEditModal}
					setShowAddEditModal={setShowAddEditModal}
					getData={getData}
					type={modalType}
					couponData={selectedCoupon}
				/>
			)}

			{showDeleteModal && (
				<Modal
					size='sm'
					title='Delete Coupon'
					content={
						<>
							<h1>Are you sure you want to delete this coupon?</h1>
							<div className='flex justify-end gap-5'>
								<Button
									title='No'
									onClick={() => setShowDeleteModal(false)}
									variant='outlined'
									color='primary'
								/>
								<Button
									title='Yes'
									onClick={() => deleteCoupon()}
									variant='contained'
									color='primary'
								/>
							</div>
						</>
					}
					modalId='delete-coupon-modal'
					open={showDeleteModal}
					setOpen={setShowDeleteModal}
				/>
			)}
		</>
	);
}

export default Coupons;
