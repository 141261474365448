import {
	NewCampaignDispatchTypes,
	UPDATE_SOCIAL_TYPE,
	UPDATE_SOCIAL_VIEW,
	UPDATE_SOCIAL_NAME,
	UPDATE_SOCIAL_DESCRIPTION,
	UPDATE_SOCIAL_ITEM,
	UPDATE_SOCIAL_ACCOUNTS,
	UPDATE_SOCIAL_FREQUENCY,
	UPDATE_SOCIAL_INTERVAL,
	UPDATE_SOCIAL_DAY,
	UPDATE_SOCIAL_SCHEDULE_DATE,
	UPDATE_SOCIAL_SCHEDULE_TIME,
	UPDATE_SOCIAL_END_DATE,
	UPDATE_SOCIAL_END_TIME,
	UPDATE_SOCIAL_TIMEZONE,
	UPDATE_SOCIAL_OCCASION,
	UPDATE_SOCIAL_SCHEDULE_TYPE,
	UPDATE_SOCIAL_OCCASION_ADDITIONALDATA,
	UPDATE_SOCIAL_OCCASION_DAY,
	UPDATE_SOCIAL_OCCASION_TIME,
	UPDATE_SOCIAL_SCHEDULE_TIMEZONE,
	UPDATE_SOCIAL_CREATIVEID,
	UPDATE_SOCIAL_CAMPAIGN_CREATIVE_EDIT,
	UPDATE_SOCIAL_CAMPAIGN_POST_TYPE,
	UPDATE_SOCIAL_CAMPAIGN_CREATIVE_CAN_EDIT,
	RESET_SOCIAL_CAMPAIGN_POST,
	OccasionOn,
	Frequency,
	Interval,
	Day,
	UPDATE_SOCIAL_CAMPAIGN_PLATFORMS,
	TimeZone,
	UPDATE_SOCIAL_SELECT_PLATFORM,
	UPDATE_SOCIAL_CAMPAIGN_ACCOUNTS,
} from './NewCampaignActionTypes';

interface INewCampaignFlow {
	currentView: number;
	campaignType: string;
	postType: string;
	name: string;
	description: string;
	item: any;
	audience: number;
	platforms: string[];
	accounts: string[];
	interval: Interval;
	frequency: Frequency;
	day: Day;
	scheduleDate: any;
	scheduleTime: any;
	endDate: any;
	endTime: any;
	schedule_timezone: any;
	occasion: string;
	occasion_AdditionalData: any;
	scheduleType: OccasionOn;
	occasion_day: number;
	occasion_time: string;
	scheduleTimeZone: TimeZone;
	socialCreativeId: string;
	isSocialCampaignCreativeEdit: boolean;
	isSocialCampaignCreativeCanEdit: string;
	selectPlatform: any;
	socialAccounts: any;
}

const initialState: INewCampaignFlow = {
	currentView: 0,
	campaignType: 'social',
	postType: 'STATUS',
	name: '',
	description: '',
	item: {},
	audience: null,
	platforms: [],
	accounts: [],
	frequency: null,
	interval: null,
	day: null,
	scheduleDate: '',
	scheduleTime: '',
	endDate: new Date(),
	endTime: '',
	schedule_timezone: '',
	occasion: '',
	scheduleType: null,
	occasion_AdditionalData: {},
	occasion_day: null,
	occasion_time: '',
	scheduleTimeZone: undefined,
	socialCreativeId: '',
	isSocialCampaignCreativeEdit: false,
	isSocialCampaignCreativeCanEdit: 'false',
	selectPlatform: {
		Facebook: false,
		Instagram: false,
		Twitter: false,
		Linkedin: false,
		Google: false,
	},
	socialAccounts: [],
};

const SocialCampaignStore = (
	state: any = initialState,
	action: NewCampaignDispatchTypes
): INewCampaignFlow => {
	switch (action.type) {
		case UPDATE_SOCIAL_TYPE:
			return { ...state, campaignType: action.campaignType };
		case UPDATE_SOCIAL_VIEW:
			return { ...state, currentView: action.currentView };
		case UPDATE_SOCIAL_NAME:
			return { ...state, name: action.name };

		case UPDATE_SOCIAL_DESCRIPTION:
			return { ...state, description: action.description };
		case UPDATE_SOCIAL_ITEM:
			return { ...state, item: action.item };
		case UPDATE_SOCIAL_CAMPAIGN_PLATFORMS:
			return { ...state, platforms: action.platforms };
		case UPDATE_SOCIAL_ACCOUNTS:
			return { ...state, accounts: action.accounts };
		case UPDATE_SOCIAL_FREQUENCY:
			return { ...state, frequency: action.frequency };
		case UPDATE_SOCIAL_INTERVAL:
			return { ...state, interval: action.interval };
		case UPDATE_SOCIAL_DAY:
			return { ...state, day: action.day };
		case UPDATE_SOCIAL_SCHEDULE_DATE:
			return { ...state, scheduleDate: action.scheduleDate };
		case UPDATE_SOCIAL_SCHEDULE_TIME:
			return { ...state, scheduleTime: action.scheduleTime };
		case UPDATE_SOCIAL_END_DATE:
			return { ...state, endDate: action.endDate };
		case UPDATE_SOCIAL_END_TIME:
			return { ...state, endTime: action.endTime };
		case UPDATE_SOCIAL_TIMEZONE:
			return { ...state, schedule_timezone: action.schedule_timezone };
		case UPDATE_SOCIAL_OCCASION:
			return { ...state, occasion: action.occasion };
		case UPDATE_SOCIAL_SCHEDULE_TYPE:
			return { ...state, scheduleType: action.scheduleType };
		case UPDATE_SOCIAL_OCCASION_ADDITIONALDATA:
			return { ...state, occasion_AdditionalData: action.occasion_AdditionalData };
		case UPDATE_SOCIAL_OCCASION_DAY:
			return { ...state, occasion_day: action.occasion_day };
		case UPDATE_SOCIAL_OCCASION_TIME:
			return { ...state, occasion_time: action.occasion_time };
		case UPDATE_SOCIAL_SCHEDULE_TIMEZONE:
			return { ...state, scheduleTimeZone: action.scheduleTimeZone };
		case UPDATE_SOCIAL_CREATIVEID:
			return { ...state, socialCreativeId: action.socialCreativeId };
		case UPDATE_SOCIAL_CAMPAIGN_CREATIVE_EDIT:
			return {
				...state,
				isSocialCampaignCreativeEdit: action.isSocialCampaignCreativeEdit,
			};
		case UPDATE_SOCIAL_CAMPAIGN_POST_TYPE:
			return { ...state, postType: action.postType };
		case UPDATE_SOCIAL_CAMPAIGN_CREATIVE_CAN_EDIT:
			return {
				...state,
				isSocialCampaignCreativeCanEdit: action.isSocialCampaignCreativeCanEdit,
			};
		case UPDATE_SOCIAL_SELECT_PLATFORM:
			return { ...state, selectPlatform: action.selectPlatform };
		case UPDATE_SOCIAL_CAMPAIGN_ACCOUNTS:
			return { ...state, socialAccounts: action.socialAccounts };
		case RESET_SOCIAL_CAMPAIGN_POST:
			return {
				...initialState,
				accounts: [],
				selectPlatform: {
					Facebook: false,
					Instagram: false,
					Twitter: false,
					Linkedin: false,
					Google: false,
				},
			};
		default:
			return state;
	}
};

export default SocialCampaignStore;
