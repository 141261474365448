import { GridColDef } from '@mui/x-data-grid-pro';
import {
	getOrganisationIdFirebase,
	getUserId,
	getUserName,
	getXProjKey,
} from 'api/AxiosManager';
import { processEmailCampaign } from 'api/Campaign';
import MUIGridGloabl from 'components/Table/MUIGlobalGrid';
import Toast, { ToastTypes } from 'components/ToastNotify';
import { Timestamp } from 'firebase/firestore';
import { GetEmailCreativeByName } from 'firebaseApis/email_creatives';
import { GetPosTeamMembers } from 'firebaseApis/pos-team-members';
import {
	GetEmailSenderProfiles,
	saveCampaign,
	saveCreativeInCampaignWithCustomVariables,
} from 'firebaseApis/single_channel_campaigns';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import Button from 'storybook-mui/build/components/Button';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';
import Tooltip from 'storybook-mui/build/components/Tooltip';
import TypographyComponent from 'storybook-mui/build/components/Typography';
import AddTeamMember from './Add';

function TeamMembers() {
	const [showAddEditModal, setShowAddEditModal] = React.useState(false);
	const [teamMembers, setTeamMembers] = React.useState<[]>([]);
	// const [showLoginPinModal, setShowLoginPinModal] = React.useState(false);
	const dispatch = useDispatch();
	const getData = async () => {
		try {
			dispatch(showLoading());
			const response = await GetPosTeamMembers();
			if (response.success) {
				setTeamMembers(response.data);
			}
			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
			Toast({ title: error.message, type: ToastTypes.ERROR });
		}
	};

	const sendLoginPin = async (data) => {
		const senderProfiles = await GetEmailSenderProfiles();
		const defaultSenderProfile = senderProfiles.data.find(
			(profile: any) => profile.sender_profile === 'Default'
		);
		const payload = {
			Approval_Status: 'N/A',
			BCC: [],
			CC: [],
			Campaign_Approver: {
				Owner_Name: '',
				User_Doc_Id: '',
			},
			Campaign_Created_By: {
				Date_And_Time: Timestamp.now(),
				Owner_Name: getUserName(),
				User_Doc_Id: getUserId(),
			},
			Campaign_Last_Modified_By: {
				Date_And_Time: Timestamp.now(),
				Owner_Name: getUserName(),
				User_Doc_Id: getUserId(),
			},
			Campaign_Name: 'Payment Request',
			Campaign_Owner: {
				Owner_Name: '',
				User_Doc_Id: '',
			},
			Campaign_Run_Type: 'runNow',
			Campaign_Status: 'Yet to start',
			Campaign_Type: 'Email',
			Description: 'Login Pin',
			Is_Canceled: false,
			Is_Processed: true,
			Is_Read: false,
			Job_ID: '',
			Last_Run_Date_Time: Timestamp.now(),
			No_Of_Total_Runs_Till_Now: 0,
			Notify_Others: [],
			Organization_Id: getOrganisationIdFirebase(),
			Pick_Segment_Filters_From_Campaign_Object: false,
			Recurring: {},
			Schedule: {},
			Schedule_Details: {},
			Segment: {},
			Segment_Type: '',
			Sender_Profile: {
				ApiKey: defaultSenderProfile.APIKey || '',
				Email: defaultSenderProfile.Email || '',
				Marketing_Campaigns: true,
				MessagingServiceID: '',
				Name: defaultSenderProfile.sender_profile || '',
				PhoneNumbers: '',
				PostText: '',
				PostVoice: '',
				PreText: '',
				PreVoice: '',
				ReplyToEmail: defaultSenderProfile.reply_to || '',
				ReplyToName: defaultSenderProfile.reply_to_name || '',
				SenderEmailName: defaultSenderProfile.sender_name || '',
			},
			Social_Accounts: [],
			Social_Info: [],
			Subject: 'Login Pin',
			Task_ID: '',
			Text: '',
			Unix_Timestamp: moment().unix(),
			X_Project_Key: getXProjKey(),
			hasDynamicHTMLVariable: false,
			plain_contentHaveDynamicVariable: false,
			subjectHaveDynamicVariable: false,
			Pick_Contact_List_From_Campaign_Object: true,
			Contact_List: [
				{
					name: `${data.First_Name} ${data.Last_Name}`,
					email: data.Email,
					phone_number: '',
					contact_id: moment().valueOf(),
				},
			],
		};
		try {
			const response: any = await saveCampaign(payload);
			const campaignId = response.id;
			const creativeToSend = await GetEmailCreativeByName('Payment Reminder');
			await saveCreativeInCampaignWithCustomVariables(
				{
					id: campaignId,
					Creative: {
						...creativeToSend,
					},
				},
				[]
			);
			await processEmailCampaign(campaignId);
			Toast({
				title: 'Payment Request Sent Successfully',
				type: ToastTypes.SUCCESS,
			});
		} catch (error) {
			Toast({ title: 'Failed', type: ToastTypes.SUCCESS });
		}
	};

	useEffect(() => {
		// fetch team members
		getData();
	}, []);

	const columns: GridColDef[] = [
		{
			headerName: 'First Name',
			field: 'First_Name',
			flex: 1,
		},
		{
			headerName: 'Last Name',

			field: 'Last_Name',
			flex: 1,
		},
		{
			headerName: 'Email',
			field: 'Email',
			flex: 1,
		},
		{
			headerName: 'Phone Number',
			field: 'Phone_Number',
			flex: 1,
		},
		{
			headerName: 'Location',
			field: 'Location',
			flex: 1,
		},
		{
			headerName: 'Role',
			field: 'Role',
			flex: 1,
		},
		{
			headerName: 'Actions',
			field: 'Actions',
			minWidth: 200,
			renderCell: (data) => (
				<div className='flex items-center gap-5'>
					<Icon icon={IconTypes.Delete} className='cursor-pointer' />
					<Icon icon={IconTypes.Edit} className='cursor-pointer' />
					<Tooltip content='Send Login Pin'>
						<Icon
							icon={IconTypes.Admin}
							className='cursor-pointer'
							onClick={() => sendLoginPin(data)}
						/>
					</Tooltip>
				</div>
			),
		},
	];
	return (
		<>
			<div className='my-2 flex items-center justify-between  w-full'>
				<TypographyComponent
					label='Team Members'
					id='Payment Terminals'
					variant='h4'
					sx={{
						fontSize: {
							lg: 30,
							xs: 20,
						},
					}}
				/>
				<Button
					title='Add Team Member'
					onClick={() => {
						setShowAddEditModal(true);
					}}
					variant='contained'
					color='primary'
				/>
			</div>

			<MUIGridGloabl
				columns={columns}
				dataSource={teamMembers}
				rowCount={teamMembers.length}
			/>
			{showAddEditModal && (
				<AddTeamMember
					showAddEditModal={showAddEditModal}
					setShowAddEditModal={setShowAddEditModal}
				/>
			)}
		</>
	);
}

export default TeamMembers;
