import { GetOrganisationOfUser } from 'firebaseApis/authentication';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import Button from 'storybook-mui/build/components/Button';
import Modal from 'storybook-mui/build/components/Modal';
import InputTextField from 'storybook-mui/build/components/TextField';
import Card from 'storybook-mui/build/components/Card';
import IconButtonComponent from 'storybook-mui/build/components/IconButton';
import { clear, getUser, getUserId, setUser } from 'api/AxiosManager';
import {
	sendSMSAuthCode,
	verifyAuthenticatorOTP,
	verifyPhoneAuth,
} from 'api/TwoFactorAuthentication';
import {
	GetDocumentInfo,
	isAuthenticationVerified,
} from 'firebaseApis/twoFactorAuthenticationFirebase';
import Toast, { ToastTypes } from 'components/ToastNotify';

import GoogleAuthIcon from 'assets/img/google_authenticator.png';
import PhoneIcon from 'assets/img/cell-phone.svg';
import LinearProgressBar from 'components/LinearProgressBar';
import { delay } from 'helpers/utils';

const User2FactorAuthentication = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [twoFAmodal, setTwoFAmodal] = useState<boolean>(true);
	const [qrCodemodal, setQRCodeModal] = useState<boolean>(false);
	const [SMSCodeModal, setSMSCodeModal] = useState<boolean>(false);
	const [backupModal, setBackupModal] = useState<boolean>(false);
	const [googleAuthCode, setGoogleAuthCode] = useState<any>('');
	const [SMSAuthCode, setSMSAuthCode] = useState<any>('');
	const [sentAgain, setSentAgain] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [currentProgress, setCurrentProgress] = useState<number>(0);
	const [isGoogleAuthVerified, setisGoogleAuthVerified] =
		useState<boolean>(false);
	const [isSMSAuthVerified, setisSMSAuthVerified] = useState<boolean>(false);
	// const [phoneNum, setPhoneNum] = useState<string>('');
	const [maskedNum, setMaskedNum] = useState<string>('');
	const [accountRecoveryModal, setAccountRecoveryModal] =
		useState<boolean>(false);

	const user = getUserId();

	const redirectToCreateOrChooseOrgPage = async (userId) => {
		try {
			dispatch(showLoading());
			const resultData = await GetOrganisationOfUser(userId);
			dispatch(hideLoading());
			if (resultData.length === 0) {
				history.push('/createorg');
			} else {
				history.push('/chooseorg');
			}
		} catch (err) {
			dispatch(hideLoading());
			history.push('/');
		}
	};

	const tryAnotherWay = () => {
		setTwoFAmodal(true);
		setQRCodeModal(false);
		setSMSCodeModal(false);
	};

	const backupFunc = async () => {
		const result = await GetDocumentInfo();
		if (
			result.acceptRisk === true ||
			(result.isGoogleAuthVerfied === true && result.isSMSAuthVerfied === true)
		) {
			setBackupModal(false);
			redirectToCreateOrChooseOrgPage(user);
		} else if (result.remindMe === true) {
			setQRCodeModal(false);
			setBackupModal(true);
		} else {
			setQRCodeModal(false);
			setBackupModal(true);
		}
	};

	// accept risk

	const handleRisk = async () => {
		dispatch(showLoading());
		const data = { acceptRisk: true, remindMe: false };
		const res = await GetDocumentInfo();
		await isAuthenticationVerified(res.id, data);
		dispatch(hideLoading());
	};

	const acceptRiskFun = () => {
		handleRisk();
		redirectToCreateOrChooseOrgPage(getUserId());
	};

	// remind me

	const handleRemind = async () => {
		dispatch(showLoading());
		const data = { remindMe: true, acceptRisk: false };
		const res = await GetDocumentInfo();
		await isAuthenticationVerified(res.id, data);
		dispatch(hideLoading());
	};

	const remindMeFun = () => {
		handleRemind();
		redirectToCreateOrChooseOrgPage(getUserId());
	};

	const GoogleAuthVerification = async () => {
		setLoading(true);
		const result = await verifyAuthenticatorOTP({
			userid: user,
			token: googleAuthCode,
		});

		if (result.verfied === true) {
			await backupFunc();
			const local = getUser();
			setUser({ ...local, isGoogleAuthEnabled: false, isSMSAuthEnabled: false });
		} else {
			Toast({
				title: 'Incorrect code',
				type: ToastTypes.ERROR,
			});
		}
		setCurrentProgress(100);
		await delay(300);
		setLoading(false);
	};

	const SMSAuthVerification = async () => {
		setLoading(true);
		const result = await verifyPhoneAuth({ userid: user, token: SMSAuthCode });
		setCurrentProgress(100);
		await delay(300);
		setLoading(false);
		if (!result) {
			Toast({
				title: 'Incorrect code',
				type: ToastTypes.ERROR,
			});
		} else {
			setSMSCodeModal(false);
			backupFunc();
			const local = getUser();
			setUser({ ...local, isSMSAuthEnabled: false, isGoogleAuthEnabled: false });
		}
	};

	// get document Id from firebase

	const getDocID = async () => {
		const result = await GetDocumentInfo();
		if (result) {
			setisGoogleAuthVerified(result.isGoogleAuthVerfied);
			setisSMSAuthVerified(result.isSMSAuthVerfied);
			if (result.Phone) {
				const x = 'X'.repeat(result.Phone.length);
				const subNum1 = result.Phone.substring(0, 3);
				const subNum = result.Phone.substring(
					result.Phone.length,
					result.Phone.length - 3
				);
				const y = x.substring(4, result.Phone.length - 2);
				const subNum2 = subNum1 + y + subNum;
				setMaskedNum(subNum2);
			}
		}
	};

	useEffect(() => {
		getDocID();
	}, []);

	const sendSMSAuth = async () => {
		await sendSMSAuthCode({ userid: user });
	};

	const openGoogleAuth = () => {
		setQRCodeModal(true);
		setTwoFAmodal(false);
	};

	const openSMSAuth = () => {
		sendSMSAuth();
		setSMSCodeModal(true);
		setTwoFAmodal(false);
	};

	const verifyGoogleAuth = () => {
		setTwoFAmodal(false);
		GoogleAuthVerification();
	};

	const verifySMSAuth = async () => {
		await SMSAuthVerification();
		setTwoFAmodal(false);
	};

	const logout = () => {
		dispatch(showLoading());
		clear();
		history.push('/');
		dispatch(hideLoading());
	};

	return (
		<div>
			{loading && (
				<LinearProgressBar
					delay={6000}
					incrementBy={1}
					currentProgress={currentProgress}
					message='Verifying...'
				/>
			)}
			<Modal
				size='xs'
				modalId='account-recovery-modal'
				open={accountRecoveryModal}
				setOpen={() => setAccountRecoveryModal(!accountRecoveryModal)}
				closeIcon
				title=''
				content={
					<div className='flex flex-col gap-4 pt-4'>
						<h1 className='font-semibold text-lg text-gray-600'>
							For Account Recovery, please contact us at +1 (469) 354-0027 or
							sales@leap360.com
						</h1>
						<div className='flex justify-end'>
							<Button
								title='OK'
								size='small'
								onClick={() => setAccountRecoveryModal(false)}
							/>
						</div>
					</div>
				}
			/>

			{/* 2-FA modal on load */}

			<Modal
				title={
					<div className='text-secondary-500 text-2xl font-semibold text-center'>
						<span>Two-Factor Authentication</span>
					</div>
				}
				size='sm'
				modalId='on-load-modal'
				open={twoFAmodal}
				setOpen={() => {}}
				content={
					<div className='flex flex-col items-center gap-6'>
						<div className='flex flex-col items-center gap-6'>
							<p className='text-gray-darkest text-sm text-center'>
								To help keep your account safe, Leap360 wants to make sure it's really
								you trying to sign in
							</p>
							<div>
								<p className='text-xl text-gray-darkest'>Choose how you sign in:</p>
							</div>
							<div>
								{isGoogleAuthVerified === true && (
									<Card
										headerSx={{ p: 0 }}
										content={
											<div onClick={openGoogleAuth} className='flex cursor-pointer flex-col justify-center items-center gap-6'>
												<div className='flex flex-row justify-center items-center gap-6'>
													<IconButtonComponent
														ariaLabel='gg-authenticator'
														id='gg-authenticator'
														content={<img src={GoogleAuthIcon} width='50' height='50' />}
														size='large'
														color='info'
													/>
													<div className='flex flex-col gap-1 lg:h-20'>
														<p>Receive verification code from the</p>
														<h4 className='text-gray-darkest font-semibold text-xl'>
															Google Authenticator App
														</h4>
													</div>
												</div>
											</div>
										}
									/>
								)}

								{isSMSAuthVerified === true && (
									<Card
										headerSx={{ p: 0 }}
										content={
											<div>
												<div onClick={openSMSAuth} className='flex cursor-pointer flex-row justify-start items-center gap-6'>
													<IconButtonComponent
														ariaLabel='text-authenticator'
														id='text-authenticator'
														content={<img src={PhoneIcon} width='50' height='50' />}
														size='large'
														color='info'
													/>
													<div className='flex flex-col gap-1 lg:h-20'>
														<p>Receive verification code at</p>
														<h4 className='text-gray-darkest font-semibold text-xl'>
															{maskedNum}
														</h4>
													</div>
												</div>
											</div>
										}
									/>
								)}
								<div className='flex flex-row justify-start items-center mt-4'>
									<div>
										<p>Try </p>
									</div>
									<Button
										title='Account Recovery'
										size='medium'
										variant='text'
										onClick={() => {
											setAccountRecoveryModal(true);
										}}
									/>
									<div>
										<p className='text-md'> instead.</p>
									</div>
								</div>
								<div className='flex justify-center mt-4'>
									<Button
										title='Back'
										size='small'
										onClick={() => {
											logout();
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				}
			/>

			{/* enter QR code modal */}
			<Modal
				title={
					<div className='text-secondary-500 text-2xl font-semibold text-center'>
						<span>Google Authenticator 2FA</span>
					</div>
				}
				sx={{ '&.MuiDialog-root': { zIndex: loading ? -1 : 1304 } }}
				size='xs'
				modalId='enter-QR-code-modal'
				open={qrCodemodal}
				setOpen={() => {}}
				content={
					<div className='flex flex-col items-center gap-6'>
						<div className='flex flex-col items-center gap-6'>
							<div>
								<p className='text-xl text-gray-darkest'>
									Verify Google Authenticator app.
								</p>
								<p className='text-gray-dark text-xs'>
									Enter 6-digit code that appears in the Google Authenticator app.
								</p>
							</div>
							<InputTextField
								labelName='Enter 6-digit code'
								name='codesix'
								fieldId='codesix'
								onChange={(e) => {
									setGoogleAuthCode(e.target.value);
								}}
								value={googleAuthCode}
								size='medium'
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										verifyGoogleAuth();
									}
								}}
							/>
						</div>
						<div className='flex justify-between space-x-44'>
							<div>
								<Button
									title='Try another way'
									size='small'
									variant='text'
									onClick={() => {
										tryAnotherWay();
									}}
								/>
							</div>
							<div className='flex justify-center'>
								<Button
									title='Verify'
									size='small'
									onClick={() => {
										verifyGoogleAuth();
									}}
								/>
							</div>
						</div>
					</div>
				}
			/>

			{/* open phone code modal */}

			<Modal
				title={
					<div className='text-secondary-500 text-2xl font-semibold text-center'>
						<span>Verify your SMS number</span>
					</div>
				}
				size='sm'
				sx={{ '&.MuiDialog-root': { zIndex: loading ? -1 : 1304 } }}
				modalId='open-phone-modal'
				open={SMSCodeModal}
				setOpen={() => {}}
				content={
					<div className='flex flex-col items-center gap-6'>
						<div className='flex flex-col items-center gap-6'>
							<p className=' text-lg text-gray-darkest'>
								Enter the code sent to your phone number to make sure everything works.
							</p>
							<div className='flex flex-col gap-2'>
								<InputTextField
									labelName='6-digit code'
									name='code'
									autoComplete='one-time-code'
									fieldId='code'
									onChange={(e) => {
										setSMSAuthCode(e.target.value);
									}}
									value={SMSAuthCode}
									size='medium'
									onKeyPress={(e) => {
										if (e.key === 'Enter') {
											verifySMSAuth();
										}
									}}
								/>
								<Button
									title='Send a new code'
									size='small'
									variant='text'
									color='info'
									onClick={() => {
										sendSMSAuth();
										setSentAgain(true);
									}}
								/>
								{sentAgain && <span>SMS sent again</span>}
							</div>
						</div>
						<div className='flex justify-between space-x-80'>
							<div>
								<Button
									title='Try another way'
									size='small'
									variant='text'
									onClick={() => {
										tryAnotherWay();
									}}
								/>
							</div>
							<div>
								<Button
									title='Verify'
									size='small'
									onClick={() => {
										verifySMSAuth();
										setSentAgain(false);
									}}
								/>
							</div>
						</div>
					</div>
				}
			/>

			{/* setup back up modal */}

			<Modal
				title={
					<div className='text-secondary-500 text-2xl font-semibold text-center'>
						<span>Setup Backup 2FA</span>
					</div>
				}
				size='sm'
				modalId='back-up-modal'
				open={backupModal}
				setOpen={() => {
					setBackupModal(false);
				}}
				content={
					<div className='flex flex-col items-center gap-6'>
						<div className='flex flex-col items-center gap-6'>
							<p className=' text-lg text-gray-darkest'>
								If you lose your first two-factor authentication, set up a second one to
								make your account more secure.
							</p>
							<div className='flex justify-center items-center gap-4 mt-6'>
								<Button
									title='Accept Risk'
									size='small'
									onClick={() => {
										acceptRiskFun();
									}}
									variant='outlined'
									color='inherit'
								/>
								<Button
									title='Remind Me Later'
									size='small'
									variant='outlined'
									color='inherit'
									onClick={() => {
										remindMeFun();
									}}
								/>
								<Button
									title='Setup Backup 2FA'
									size='small'
									onClick={() => {
										history.push('/signup2FA');
									}}
								/>
							</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default User2FactorAuthentication;
