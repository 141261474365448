import {
	SET_NEW_ORG_DATA,
	SET_NEW_ORG_FLOW_STEP,
	CANCEL_NEW_ORG_FLOW,
	SET_NEW_ORG_FLOW_TYPE,
} from './CreateOrgActionTypes';

export interface INewOrgInitialState {
	Step: number;
	Org_Flow_Type: 'PARENT' | 'SUB';
	Org_Data: {
		Account_Type: string;
		Org_Name: string;
		Org_Strength: string;
		Country: string;
		Time_Zone: string;
		Industry: string;
		User_City: string;
		User_State: string;
		User_Locality: string;
		Features_Interested: any[];
		Quantity: any;
		Quantity_Prices: any;
	};
}

const initialState: INewOrgInitialState = {
	Step: 0,
	Org_Flow_Type: 'PARENT',
	Org_Data: {
		Account_Type: 'business',
		Org_Name: '',
		Org_Strength: '',
		Country: '',
		Time_Zone: '',
		Industry: '',
		User_City: '',
		User_State: '',
		User_Locality: '',
		Features_Interested: [],
		Quantity: {
			Email: 0,
			SMS: 0,
			MMS: 0,
			Voice: 0,
			Social: 0,
		},
		Quantity_Prices: {
			Email: 0,
			SMS: 0,
			MMS: 0,
			Voice: 0,
			Social: 0,
		},
	},
};

const createOrgReducer = (state = initialState, action: any) => {
	switch (action.type) {
		case SET_NEW_ORG_DATA:
			return {
				...state,
				Org_Data: action.payload,
			};
		case SET_NEW_ORG_FLOW_STEP:
			return {
				...state,
				Step: action.payload,
			};
		case SET_NEW_ORG_FLOW_TYPE:
			return {
				...state,
				Org_Flow_Type: action.payload,
			};
		case CANCEL_NEW_ORG_FLOW:
			return initialState;
		default:
			return state;
	}
};

export default createOrgReducer;
