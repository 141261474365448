export const UPDATE_TYPE = 'UPDATE_TYPE';
export const UPDATE_VIEW = 'UPDATE_VIEW';
export const Update_SMS_CREATIVE_ID_IN_CAMPAIGN =
	'Update_SMS_CREATIVE_ID_IN_CAMPAIGN';
export const Update_VOICE_CREATIVE_ID_IN_CAMPAIGN =
	'Update_VOICE_CREATIVE_ID_IN_CAMPAIGN';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const UPDATE_NAME = 'UPDATE_NAME';
export const UPDATE_DESCRIPTION = 'UPDATE_DESCRIPTION';
export const UPDATE_AUDEIENCE = 'UPDATE_AUDEIENCE';
export const UPDATE_ACCOUNTS = 'UPDATE_ACCOUNTS';
export const UPDATE_PLATFORMS = 'UPDATE_PLATFORMS';
export const UPDATE_FREQUENCY = 'UPDATE_FREQUENCY';
export const UPDATE_INTERVAL = 'UPDATE_INTERVAL';
export const UPDATE_DAY = 'UPDATE_DAY';
export const UPDATE_CAMPAIGN_DETAILS = 'UPDATE_CAMPAIGN_DETAILS';
export const UPDATE_CAMPAIGN_RUN_TYPE = 'UPDATE_CAMPAIGN_RUN_TYPE';
export const SET_IN_CAMPAIGN = 'SET_IN_CAMPAIGN';
export const UPDATE_SCHEDULE_DETAILS = 'UPDATE_SCHEDULE_DETAILS';
export const UPDATE_TRIGGER_DETAILS = 'UPDATE_TRIGGER_DETAILS';

export const SET_CAMPAIGN_COLORS = 'SET_CAMPAIGN_COLORS';
export const SET_ORGANIZATION_USERS = 'SET_ORGANIZATION_USERS';
export const SET_SENDER_PROFILES = 'SET_SENDER_PROFILES';

export const UPDATE_START_DATE = 'UPDATE_START_DATE';
export const UPDATE_START_TIME = 'UPDATE_START_TIME';

export const UPDATE_END_DATE = 'UPDATE_END_DATE';
export const UPDATE_END_TIME = 'UPDATE_END_TIME';
export const UPDATE_TIMEZONE = 'UPDATE_TIMEZONE';
export const UPDATE_OCCASION = 'UPDATE_OCCASION';
export const UPDATE_OCCASION_ON = 'UPDATE_OCCASION_ON';
export const UPDATE_OCCASION_ADDITIONALDATA = 'UPDATE_OCCASION_ADDITIONALDATA';
export const UPDATE_OCCASION_DAY = 'UPDATE_OCCASION_DAY';
export const UPDATE_OCCASION_TIME = 'UPDATE_OCCASION_TIME';
export const UPDATE_OCCASION_TIMEZONE = 'UPDATE_OCCASION_TIMEZONE';
export const UPDATE_IS_PROCESSED = 'UPDATE_IS_PROCESSED';
export const SET_CAMPAIGN_SUCCESS = 'SET_CAMPAIGN_SUCCESS';
export const UPDATE_CRON_SCHEDULE_VALUE = 'UPDATE_CRON_SCHEDULE_VALUE';
export const UPDATE_EMAIL_CONTENT = 'UPDATE_EMAIL_CONTENT';
export const UPDATE_EMAIL_SUBJECT = 'UPDATE_EMAIL_SUBJECT';
export const UPDATE_CC_EMAIL = 'UPDATE_CC_EMAIL';
export const UPDATE_BCC_EMAIL = 'UPDATE_BCC_EMAIL';
export const RESET_CAMPAIGN_POST = 'RESET_CAMPAIGN_POST';
export const UPDATE_NOTIFY_OTHERS = 'UPDATE_NOTIFY_OTHERS';

export interface Update_SMS_CREATIVE_ID_IN_CAMPAIGN {
	type: typeof Update_SMS_CREATIVE_ID_IN_CAMPAIGN;
	id: any;
}
export interface UPDATE_NOTIFY_OTHERS {
	type: typeof UPDATE_NOTIFY_OTHERS;
	notifyOthers: any;
}
export interface Update_VOICE_CREATIVE_ID_IN_CAMPAIGN {
	type: typeof Update_VOICE_CREATIVE_ID_IN_CAMPAIGN;
	id: any;
}
export interface SET_SENDER_PROFILES {
	type: typeof SET_SENDER_PROFILES;
	senderProfiles: any;
}

export interface SET_CAMPAIGN_COLORS {
	type: typeof SET_CAMPAIGN_COLORS;
	campaignColors: any;
}

export interface SET_ORGANIZATION_USERS {
	type: typeof SET_ORGANIZATION_USERS;
	organizationUsers: any;
}

export interface UPDATE_TYPE {
	type: typeof UPDATE_TYPE;
	campaignType: string;
}

export interface UPDATE_VIEW {
	type: typeof UPDATE_VIEW;
	currentView: number;
}

export interface UPDATE_ITEM {
	type: typeof UPDATE_ITEM;
	item: any;
}

export interface UPDATE_NAME {
	type: typeof UPDATE_NAME;
	name: string;
}

export interface UPDATE_DESCRIPTION {
	type: typeof UPDATE_DESCRIPTION;
	description: string;
}

export interface UPDATE_AUDEIENCE {
	type: typeof UPDATE_AUDEIENCE;
	audience: number;
}
export interface UPDATE_PLATFORMS {
	type: typeof UPDATE_PLATFORMS;
	platforms: string[];
}
export interface UPDATE_CC_EMAIL {
	type: typeof UPDATE_CC_EMAIL;
	ccEmails: string;
}
export interface UPDATE_EMAIL_CONTENT {
	type: typeof UPDATE_EMAIL_CONTENT;
	plainContent: string;
}
export interface UPDATE_EMAIL_SUBJECT {
	type: typeof UPDATE_EMAIL_SUBJECT;
	subject: string;
}
export interface UPDATE_CAMPAIGN_RUN_TYPE {
	type: typeof UPDATE_CAMPAIGN_RUN_TYPE;
	runType: string;
}
export interface UPDATE_BCC_EMAIL {
	type: typeof UPDATE_BCC_EMAIL;
	bccEmails: string;
}
export interface UPDATE_ACCOUNTS {
	type: typeof UPDATE_ACCOUNTS;
	accounts: string[];
}
export interface UPDATE_INTERVAL {
	type: typeof UPDATE_INTERVAL;
	interval: Interval;
}

export interface UPDATE_FREQUENCY {
	type: typeof UPDATE_FREQUENCY;
	frequency: Frequency;
}

export interface UPDATE_DAY {
	type: typeof UPDATE_DAY;
	day: Day;
}

export interface UPDATE_START_DATE {
	type: typeof UPDATE_START_DATE;
	startDate: any;
}

export interface UPDATE_START_TIME {
	type: typeof UPDATE_START_TIME;
	startTime: any;
}

export interface UPDATE_END_DATE {
	type: typeof UPDATE_END_DATE;
	endDate: any;
}

export interface UPDATE_END_TIME {
	type: typeof UPDATE_END_TIME;
	endTime: any;
}
export interface UPDATE_TIMEZONE {
	type: typeof UPDATE_TIMEZONE;
	schedule_timezone: TimeZone;
}
export interface UPDATE_CRON_SCHEDULE_VALUE {
	type: typeof UPDATE_CRON_SCHEDULE_VALUE;
	cronScheduleValue: string;
}

export interface UPDATE_OCCASION {
	type: typeof UPDATE_OCCASION;
	occasion: string;
}

export interface UPDATE_OCCASION_ON {
	type: typeof UPDATE_OCCASION_ON;
	occasion_on: OccasionOn;
}
export interface UPDATE_OCCASION_ADDITIONALDATA {
	type: typeof UPDATE_OCCASION_ADDITIONALDATA;
	occasion_AdditionalData: OccasionOn;
}

export interface UPDATE_OCCASION_DAY {
	type: typeof UPDATE_OCCASION_DAY;
	occasion_day: number;
}
export interface UPDATE_OCCASION_TIME {
	type: typeof UPDATE_OCCASION_TIME;
	occasion_time: string;
}
export interface UPDATE_OCCASION_TIMEZONE {
	type: typeof UPDATE_OCCASION_TIMEZONE;
	occasion_timezone: TimeZone;
}
export interface UPDATE_IS_PROCESSED {
	type: typeof UPDATE_IS_PROCESSED;
	isProcessed: boolean;
}
export interface SET_CAMPAIGN_SUCCESS {
	type: typeof SET_CAMPAIGN_SUCCESS;
	campaign_success: boolean;
}
export interface RESET_CAMPAIGN_POST {
	type: typeof RESET_CAMPAIGN_POST;
}

export interface UPDATE_CAMPAIGN_DETAILS {
	type: typeof UPDATE_CAMPAIGN_DETAILS;
	campaignDetails: any;
}
export interface UPDATE_SCHEDULE_DETAILS {
	type: typeof UPDATE_SCHEDULE_DETAILS;
	scheduleDetails: any;
}
export interface UPDATE_TRIGGER_DETAILS {
	type: typeof UPDATE_TRIGGER_DETAILS;
	triggerDetails: any;
}

export interface SET_IN_CAMPAIGN {
	type: typeof SET_IN_CAMPAIGN;
	inCampaign: any;
}

export enum Interval {
	Once = 'Once',
	Recurs = 'Recurs',
	Scheduled = 'Scheduled',
}

export enum Frequency {
	Daily = 'Daily',
	Weekly = 'Weekly',
	Monthly = 'Monthly',
	Yearly = 'Yearly',
}

export enum OccasionOn {
	'before',
	'on',
	'after',
}
export const OccasionData = [
	{
		key: 'before',
		value: 'lt',
	},
	{
		key: 'on',
		value: 'eq',
	},
	{
		key: 'after',
		value: 'gt',
	},
];

export enum Day {
	Sunday = 0,
	Monday = 1,
	Tuesday = 2,
	Wednesday = 3,
	Thursday = 4,
	Friday = 5,
	Saturday = 6,
}
export enum TimeZone {
	'CST',
	'MST',
	'PST',
	'EST',
	'AST',
	'CHT',
	'ST',
	'AKT',
}

export type NewCampaignDispatchTypes =
	| Update_SMS_CREATIVE_ID_IN_CAMPAIGN
	| Update_VOICE_CREATIVE_ID_IN_CAMPAIGN
	| SET_SENDER_PROFILES
	| SET_CAMPAIGN_COLORS
	| SET_ORGANIZATION_USERS
	| UPDATE_TYPE
	| UPDATE_VIEW
	| UPDATE_ITEM
	| UPDATE_DESCRIPTION
	| UPDATE_NAME
	| UPDATE_AUDEIENCE
	| UPDATE_PLATFORMS
	| UPDATE_ACCOUNTS
	| UPDATE_FREQUENCY
	| UPDATE_INTERVAL
	| UPDATE_DAY
	| UPDATE_START_DATE
	| UPDATE_START_TIME
	| UPDATE_END_DATE
	| UPDATE_END_TIME
	| UPDATE_TIMEZONE
	| UPDATE_OCCASION
	| UPDATE_OCCASION_ON
	| UPDATE_OCCASION_ADDITIONALDATA
	| UPDATE_OCCASION_DAY
	| UPDATE_OCCASION_TIME
	| UPDATE_OCCASION_TIMEZONE
	| UPDATE_IS_PROCESSED
	| SET_CAMPAIGN_SUCCESS
	| UPDATE_CRON_SCHEDULE_VALUE
	| UPDATE_EMAIL_CONTENT
	| UPDATE_EMAIL_SUBJECT
	| UPDATE_NOTIFY_OTHERS
	| UPDATE_CAMPAIGN_RUN_TYPE
	| UPDATE_CC_EMAIL
	| RESET_CAMPAIGN_POST
	| UPDATE_BCC_EMAIL
	| UPDATE_CAMPAIGN_DETAILS
	| UPDATE_SCHEDULE_DETAILS
	| SET_IN_CAMPAIGN
	| UPDATE_TRIGGER_DETAILS;
