import React, { useState } from 'react';
import Toast, { ToastTypes } from 'components/ToastNotify';
import { useFormik } from 'formik';
import CryptoJS from 'crypto-js';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { AddExistingUserToOrganisation } from 'firebaseApis/users';
// Storybook Components
import Button from 'storybook-mui/build/components/Button';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';
import InputTextField from 'storybook-mui/build/components/TextField';

import { BasicRegister } from 'firebaseApis/authentication';
import {
	SetAuthenticationType,
	SetUserInfo,
} from 'reducers/Authentication/AuthActions';
import { showLoading, hideLoading } from 'reducers/Alerts/AlertActions';
import { signupSchema } from './validations/signupValidation';

export const JoinFromInvite = () => {
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { token } = queryString?.parse(search);
	const bytes = CryptoJS.AES.decrypt(token.replaceAll(' ', '+'), 'LEAP360');
	const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	const { organizationId, organizationName, email, clientDb } = decryptedData;
	const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
	const [confirmPassword, setConfirmPassword] = useState<string>('');
	const [passwordVisibility, setPasswordVisibility] = useState(true);
	const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
		useState(true);
	const [validationOnChange, setValidateOnChange] = useState(false);
	const history = useHistory();
	const registerUser = async (values: any) => {
		try {
			dispatch(showLoading());
			const result = await BasicRegister(values);
			if (result.success) {
				const dataForLocalStorage: any = {
					fName: values.FirstName,
					lName: values.LastName,
					email,
					userId: result.data.id,
					emailVerified: false,
				};
				Toast({ title: 'Registration Successfull', type: ToastTypes.SUCCESS });
				dispatch(SetUserInfo(values));
				dispatch(SetAuthenticationType('REGISTER'));
				const result2 = await AddExistingUserToOrganisation(
					dataForLocalStorage.userId,
					organizationId,
					organizationName,
					clientDb
				);
				if (result2.success) {
					dispatch(SetUserInfo(dataForLocalStorage));
					history.push('/verifyemail');
				} else {
					Toast({ title: result2.message, type: ToastTypes.ERROR });
				}
				dispatch(hideLoading());
			} else {
				dispatch(hideLoading());
				Toast({ title: 'Email already exists', type: ToastTypes.ERROR });
			}
		} catch (error) {
			dispatch(hideLoading());
			Toast({ title: 'Something Went Wrong', type: ToastTypes.ERROR });
		}
	};

	const registerForm = useFormik({
		validationSchema: signupSchema,
		validateOnChange: validationOnChange,
		initialValues: {
			FirstName: '',
			LastName: '',
			Email: email,
			Name: '',
			Password: '',
		},
		onSubmit: () => {
			setShowConfirmPassword(true);
		},
	});

	return (
		<div className='flex h-screen items-center justify-center'>
			<div className='shadow bg-white p-5'>
				<h1 className='text-3xl font-semibold text-gray-600'>Leap360 - SIGNUP</h1>
				{!showConfirmPassword && (
					<form className='flex mb-3 gap-4 flex-col lg:flex-row mt-5'>
						<div className='flex flex-col w-full space-y-4'>
							<div className='flex flex-col lg:flex-row lg:space-x-5 lg:space-y-0 space-y-4'>
								<InputTextField
									name='FirstName'
									fieldId='firstName'
									labelName='First Name'
									value={registerForm.values.FirstName}
									errorMessage='Required'
									valid={registerForm.errors.FirstName === undefined}
									onChange={registerForm.handleChange}
									autoComplete='given-name'
								/>
								<InputTextField
									name='LastName'
									fieldId='lastName'
									labelName='Last Name'
									value={registerForm.values.LastName}
									errorMessage='Required'
									valid={registerForm.errors.LastName === undefined}
									onChange={registerForm.handleChange}
									autoComplete='family-name'
								/>
							</div>
							<div className='flex flex-col lg:flex-row lg:space-x-5 lg:space-y-0 space-y-4'>
								<InputTextField
									name='Email'
									fieldId='email'
									labelName="What's a good email ?"
									value={email}
									onChange={() => {}}
									autoComplete='email'
									disabled
								/>
								<InputTextField
									autoComplete='new-password'
									name='Password'
									type={passwordVisibility ? 'password' : 'text'}
									fieldId='password'
									labelName='Password'
									value={registerForm.values.Password}
									errorMessage={registerForm.errors.Password}
									valid={registerForm.errors.Password === undefined}
									onChange={registerForm.handleChange}
									adornmentMessage={
										<IconButton
											aria-label='toggle password visibility'
											onClick={() => setPasswordVisibility(!passwordVisibility)}
											onMouseDown={(event) => event.preventDefault()}
										>
											{passwordVisibility ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									}
								/>
							</div>
						</div>

						<Button
							onClick={() => {
								setValidateOnChange(true);
								registerForm.handleSubmit();
							}}
							title='next'
							endIcon={<Icon icon={IconTypes.ArrowForwardIos} />}
							disabled={
								registerForm.values.Email &&
								registerForm.values.FirstName &&
								registerForm.values.LastName &&
								registerForm.values.Password &&
								false
							}
						/>
					</form>
				)}
				{showConfirmPassword && (
					<div className='flex mb-3 gap-4 flex-col lg:flex-row'>
						<div
							onClick={() => setShowConfirmPassword(false)}
							className='hidden lg:flex flex-col justify-center items-center p-5 text-gray cursor-pointer hover:shadow-md'
						>
							<Icon icon={IconTypes.ArrowBackIos} />
							<h1 className='text-xl font-semibold underline'>Edit</h1>
						</div>
						<div className='flex flex-col w-full space-y-4'>
							<div className='flex flex-col'>
								<h1 className='mb-4 text-primary-500 text-xl'>
									{registerForm.values.Email}
								</h1>
								<InputTextField
									name='ConfirmPassword'
									type={confirmPasswordVisibility ? 'password' : 'text'}
									autoComplete='new-password'
									fieldId='ConfirmPassword'
									labelName='Confirm Password'
									valid={
										!confirmPassword || confirmPassword === registerForm.values.Password
									}
									errorMessage='Password does not match'
									value={confirmPassword}
									onChange={(e: any) => setConfirmPassword(e.target.value)}
									adornmentMessage={
										<IconButton
											aria-label='toggle password visibility'
											onClick={() =>
												setConfirmPasswordVisibility(!confirmPasswordVisibility)
											}
											onMouseDown={(event) => event.preventDefault()}
										>
											{confirmPasswordVisibility ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									}
								/>
							</div>
						</div>
						<div className='flex items-center justify-between lg:justify-end'>
							<div className='block lg:hidden'>
								<Button
									onClick={() => setShowConfirmPassword(false)}
									title='Edit'
									color='info'
									variant='outlined'
									startIcon={<Icon icon={IconTypes.ArrowBackIos} />}
								/>
							</div>
							<Button
								onClick={() =>
									confirmPassword === registerForm.values.Password &&
									registerUser(registerForm.values)
								}
								title='next'
								endIcon={<Icon icon={IconTypes.ArrowForwardIos} />}
								disabled={
									!confirmPassword || confirmPassword !== registerForm.values.Password
								}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default JoinFromInvite;
