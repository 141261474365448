import { Dispatch } from 'redux';
import { SET_NEW_ORG_DATA , SET_NEW_ORG_FLOW_STEP , CANCEL_NEW_ORG_FLOW , SET_NEW_ORG_FLOW_TYPE} from './CreateOrgActionTypes';


export const SetNewOrgData  = (data: any) => async (dispatch: Dispatch<any>) => {
    dispatch({ type: SET_NEW_ORG_DATA, payload: data });
}

export const SetNewOrgFlowStep = (step: number) => async (dispatch: Dispatch<any>) => {
    dispatch({ type: SET_NEW_ORG_FLOW_STEP, payload: step });
}

export const CancelNewOrgFlow = () => async (dispatch: Dispatch<any>) => {
    dispatch({ type: CANCEL_NEW_ORG_FLOW});
}

export const SetNewOrgFlowType = (type: string) => async (dispatch: Dispatch<any>) => {
    dispatch({ type: SET_NEW_ORG_FLOW_TYPE, payload: type });
}

 