import {
	ADD_VOICE_REPLY,
	RESET_VOICE_CREATIVE,
	SET_VOICE_MAIN_MESSAGE,
	SET_VOICE_NODE_ID,
	SET_VOICE_REPLY,
	SET_VOICE_RESPONSE,
	SET_VOICE_TREE_DATA,
	SET_VOICE_VIEW,
	SET_VOICE_SPEAKER_LANGUAGES,
	SET_VOICE_SPEAKERS,
	SET_VOICE_CREATIVE_NAME,
	SET_VOICE_SELECTED_CATEGORY,
	SET_VOICE_CATEGORIES,
	SET_VOICE_SELECTED_SUBCATEGORY,
	SET_VOICE_MAIN_MESSAGE_HAVE_DYNAMIC,
} from './VoiceCreativesActionTypes';

interface ISMSCreative {
	voiceTreeData: any;
	view: number;
	voiceReplies: [];
	voiceReply: string;
	voiceResponse: any;
	id: string;
	currentId: string;
	mainMessage: any;
	voiceSpeakerLanguages: [];
	voiceSpeakers: [];
	mainMessageHaveDynamicVariable: boolean;
}

const settings = {
	response_voice: 'alice',
	voiceSpeakerUrl:
		'https://storage.googleapis.com/voice_speaker_sound/Alice.mp3',
	response_langugage: 'en-US',
	if_no_input_response: [],
	if_wrong_input_response: [],
	input_mode: '',
	input_timeout: 5,
	sameSettings: true,
	speech: true,
	dtmf: true,
};
const initialState: ISMSCreative = {
	voiceTreeData: { children: [], ...settings },
	view: 1,
	voiceReplies: [],
	voiceReply: '',
	voiceResponse: [],
	id: '',
	currentId: '',
	mainMessage: [],
	voiceSpeakerLanguages: [],
	voiceSpeakers: [],
	mainMessageHaveDynamicVariable: false,
};

const voiceCreativesReducer = (state: any = initialState, action) => {
	switch (action.type) {
		case SET_VOICE_RESPONSE:
			return { ...state, voiceResponse: action.payload };
		case SET_VOICE_MAIN_MESSAGE:
			return { ...state, mainMessage: action.payload };
		case SET_VOICE_MAIN_MESSAGE_HAVE_DYNAMIC:
			return { ...state, mainMessageHaveDynamicVariable: action.payload };

		case SET_VOICE_TREE_DATA:
			return { ...state, voiceTreeData: action.payload };
		case SET_VOICE_VIEW:
			return { ...state, view: action.payload };
		case SET_VOICE_REPLY:
			return { ...state, voiceReply: action.payload };
		case SET_VOICE_NODE_ID:
			return { ...state, currentId: action.payload };
		case ADD_VOICE_REPLY:
			return { ...state, voiceReplies: action.payload };
		case SET_VOICE_SPEAKER_LANGUAGES:
			return { ...state, voiceSpeakerLanguages: action.payload };
		case SET_VOICE_SPEAKERS:
			return { ...state, voiceSpeakers: action.payload };
		case SET_VOICE_CREATIVE_NAME:
			return { ...state, creativeName: action.payload };
		case SET_VOICE_SELECTED_CATEGORY:
			return { ...state, selectedCategory: action.payload };
		case SET_VOICE_CATEGORIES:
			return { ...state, categories: action.payload };
		case SET_VOICE_SELECTED_SUBCATEGORY:
			return { ...state, selectedSubCategory: action.payload };
		case RESET_VOICE_CREATIVE:
			return {
				voiceTreeData: { children: [], ...settings },
				mainMessageHaveDynamicVariable:false,
				view: 1,
				voiceReplies: [],
				voiceReply: '',
				voiceResponse: [],
				id: '',
				currentId: '',
				mainMessage: [],
				voiceSpeakerLanguages: [],
				voiceSpeakers: [],
			};
		default:
			return state;
	}
};

export default voiceCreativesReducer;
