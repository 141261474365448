import { Dispatch } from 'redux';
import {
	AuthDispatchTypes,
	SET_USER_INFO,
	SET_AUTHENTICAION_FLOW_VIEW,
	SET_USER_ACCOUNT_INFO,
	SET_AUTHENTICATION_TYPE,
	CANCEL_ORG_CREATON_FLOW,
} from './AuthActionTypes';

export const SetAuthenticationFlowView =
	(view: number) => async (dispatch: Dispatch<AuthDispatchTypes>) =>
		dispatch({ type: SET_AUTHENTICAION_FLOW_VIEW, payload: view });

export const SetUserInfo =
	(data: any) => async (dispatch: Dispatch<AuthDispatchTypes>) =>
		dispatch({ type: SET_USER_INFO, payload: data });

export const SetUserAccountInfo =
	(data: any) => async (dispatch: Dispatch<AuthDispatchTypes>) =>
		dispatch({ type: SET_USER_ACCOUNT_INFO, payload: data });

export const SetAuthenticationType =
	(type: 'LOGIN' | 'REGISTER') =>
	async (dispatch: Dispatch<AuthDispatchTypes>) =>
		dispatch({ type: SET_AUTHENTICATION_TYPE, payload: type });

export const CancelOrgCreationFlow = () => async (dispatch: Dispatch<any>) =>
	dispatch({ type: CANCEL_ORG_CREATON_FLOW });
