import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import {
	getMasterSiteConfig,
	setEmailBuilderAssetsFromStorage,
} from 'firebaseApis/authentication';
import { Spinner } from 'components/Spinner';
import { getUser } from 'api/AxiosManager';

export interface IScroll {
	children: React.ReactNode;
}

const ScrollToTop = ({ children }: IScroll) => {
	const location = useLocation();
	const [siteConfig, setSiteConfig] = useState<any>(null);
	const fetchData = async () => {
		const result = await getMasterSiteConfig(window.location.origin);
		if (result) {
			setSiteConfig(result.Constant_Value);
			localStorage.setItem('siteConfig', JSON.stringify(result.Constant_Value));
		}
	};

	const user = getUser();

	const getEmailAssetStorageBucket = async () => {
		const result = await setEmailBuilderAssetsFromStorage(window.location.origin);
		if (result) {
			localStorage.setItem(
				'emailBuilderAssetsBucket',
				JSON.stringify(result.Constant_Value)
			);
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		const siteConfigObj = localStorage.getItem('siteConfig');
		if (siteConfigObj === 'undefined' || !JSON.parse(siteConfigObj)) {
			fetchData();
		} else {
			setSiteConfig(JSON.parse(siteConfigObj));
		}

		const emailBuilderBucket = localStorage.getItem('emailBuilderAssetsBucket');

		if (!JSON.parse(emailBuilderBucket) && user?.organisationPathFirebase) {
			getEmailAssetStorageBucket();
		}
	}, [location]);

	if (siteConfig) {
		return <>{children}</>;
	}
	return <Spinner />;
};

export default ScrollToTop;
