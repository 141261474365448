export const SELECTED_WEBSITE = 'SELECTED_WEBSITE';
export const SELECTED_PAGE = 'SELECTED_PAGE';
export const SELECTED_PAGE_CONTENT = 'SELECTED_PAGE_CONTENT';

export const TOTAL_PAGES = 'TOTAL_PAGES';
export const BOOKING_NAV = 'BOOKING_NAV';
export const DEFAULT_HEADER = 'DEFAULT_HEADER';
export const DEFAULT_FOOTER = 'DEFAULT_FOOTER';

export const ACTIVE_STICKY = 'ACTIVE_STICKY';

export const SHOW_SECTIONS = 'SHOW_SECTIONS';
export const SHOW_ROWS = 'SHOW_ROWS';
export const SHOW_CELLS = 'SHOW_CELLS';
