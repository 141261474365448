import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export enum ToastTypes {
	SUCCESS = 'success',
	ERROR = 'error',
	WARN = 'warn',
	INFO = 'info',
}

function Toast({ title, type = ToastTypes.WARN, toastId, className }: toastProps) {
	toast[type](title, {
		position: toast.POSITION.TOP_RIGHT,
		className,
		toastId
	});
}

export interface toastProps {
	title?: string;
	type?: ToastTypes;
	toastId?: string;
	className?: string;
}

export default Toast;
