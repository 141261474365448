import { SET_TABLE_DETAILS, SET_TABLE_VIEW_TYPE } from './TableConstants';

interface ITable {
	table: any;
}

const initialState: ITable = {
	table: {
		baseUrl: '',
		pageNumber: 0,
		viewType: 'table',
	},
};

const tableReducer = (state: any = initialState, action: any): ITable => {
	switch (action.type) {
		case SET_TABLE_DETAILS:
			return {
				...state,
				table: {
					...state.table,
					baseUrl: action.payload.baseUrl,
					pageNumber: action.payload.pageNumber,
				},
			};
		case SET_TABLE_VIEW_TYPE:
			return {
				...state,
				table: { ...state.table, viewType: action.payload.viewType },
			};

		default:
			return state;
	}
};

export default tableReducer;
