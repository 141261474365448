export const SET_DYNAMIC_JSON = 'SET_DYNAMIC_JSON';
export const SET_DYNAMIC_JSON_VALUES = 'SET_DYNAMIC_JSON_VALUES';
export const SET_DYNAMIC_JSON_TYPE = 'SET_DYNAMIC_JSON_TYPE';
export const SET_DYNAMIC_JSON_MODULE_NAME = 'SET_DYNAMIC_JSON_MODULE_NAME';
export const RELOAD_FIELD_OPTIONS_LIST = 'RELOAD_FIELD_OPTIONS_LIST';
export const SET_VALIDATION_ON_CHANGE = 'SET_VALIDATION_ON_CHANGE';
export const SET_NEW_MODULE_DETAILS = 'SET_NEW_MODULE_DETAILS';
export const SET_NEW_FIELD_DETAILS = 'SET_NEW_FIELD_DETAILS';
export const SET_NAV_MENU = 'SET_NAV_MENU';
export const SET_DYNAMIC_MODULES = 'SET_DYNAMIC_MODULES';
export const RESET_DYNAMIC_FORM = 'RESET_DYNAMIC_FORM';
export const SET_GROUP_FIELDS = 'SET_GROUP_FIELDS';
export const SET_SAMPLE_JSONS = 'SET_SAMPLE_JSONS';
