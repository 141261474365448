export const SET_SOCIAL_POSTS_BRAND_MANAGEMENT =
	'SET_SOCIAL_POSTS_BRAND_MANAGEMENT';
export const SET_SOCIAL_FEED_BRAND_MANAGEMENT =
	'SET_SOCIAL_FEED_BRAND_MANAGEMENT';
export const SET_SOCIAL_ACCOUNTS_BRAND_MANAGEMENT =
	'SET_SOCIAL_ACCOUNTS_BRAND_MANAGEMENT';
export const SET_SOCIAL_PAGES_BRAND_MANAGEMENT =
	'SET_SOCIAL_PAGES_BRAND_MANAGEMENT';
export const SET_SOCIAL_SELECTED_ACCOUNT_BRAND_MANAGEMENT =
	'SET_SOCIAL_SELECTED_ACCOUNT_BRAND_MANAGEMENT';
export const SET_SOCIAL_SELECTED_PAGE_BRAND_MANAGEMENT =
	'SET_SOCIAL_SELECTED_PAGE_BRAND_MANAGEMENT';
export const SET_SOCIAL_SELECTED_PLATFORM_BRAND_MANAGEMENT =
	'SET_SOCIAL_SELECTED_PLATFORM_BRAND_MANAGEMENT';
export const SET_SOCIAL_SHOW_PAGES_MANAGEMENT =
	'SET_SOCIAL_SHOW_PAGES_MANAGEMENT';

export const SET_FILTERED_SOCIAL_ACCOUNTS_BRAND_MANAGEMENT =
	'SET_FILTERED_SOCIAL_ACCOUNTS_BRAND_MANAGEMENT';
export const RESET_FEEDS_DATA = 'RESET_FEEDS_DATA';
