import { Dispatch } from 'redux';
import {
	LayoutDispatchTypes,
	TOGGLE_COLLAPSE,
	TOGGLE_HEADER_NAV,
	RELOAD_LAYOUT,
} from './LayoutActionTypes';

export const ToggleCollapse =
	() => async (dispatch: Dispatch<LayoutDispatchTypes>) =>
		dispatch({ type: TOGGLE_COLLAPSE });

export const ToggleHeaderNav =
	(data) => async (dispatch: Dispatch<LayoutDispatchTypes>) =>
		dispatch({ type: TOGGLE_HEADER_NAV, payload: data });

export const ReloadLayout =
	(val) => async (dispatch: Dispatch<LayoutDispatchTypes>) =>
		dispatch({ type: RELOAD_LAYOUT, payload: val });
