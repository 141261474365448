import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Toast, { ToastTypes } from 'components/ToastNotify';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from 'storybook-mui/build/components/Button';
import CryptoJS from 'crypto-js';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';
import IconButtonComponent from 'storybook-mui/build/components/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Modal from 'storybook-mui/build/components/Modal';
import BadgeComponent from 'storybook-mui/build/components/Badge';
import { ReloadLayout } from 'reducers/Layout/LayoutActions';
import { Logout } from 'reducers/user/UserActions';
import {
	clear,
	getOrganisationIdFirebase,
	getUser,
	siteConfigConstants,
} from 'api/AxiosManager';
import copy from 'copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
	GetOrganisationInfoById,
	GetOrganisationOfUser,
} from 'firebaseApis/authentication';
import AvatarComponent from 'storybook-mui/build/components/Avatar';
import {
	ClearNotifications,
	GetNotifications,
	UpdateCampaignsUnReadCount,
	UpdateReceivedNote,
	UpdateSocialUnReadCount,
} from 'firebaseApis/socialmedia';
import { getDateFormat, getDateTimeWithUnix } from 'helpers/Formats';
import { UpdateCampaignDetails } from 'firebaseApis/single_channel_campaigns';
import { RootStore } from 'reducers';
import LinearProgressBar from 'components/LinearProgressBar';
import { delay } from 'helpers/utils';

export function WelcomeUser() {
	const [orgData, setOrgData] = useState<[]>([]);
	const [notificationData, setNotificationData] = useState<any>([]);
	const [userName, setUserName] = useState<string>('');
	const [count, setCount] = useState<number>(0);
	const [showOrganisationsModal, setShowOrganisationsModal] =
		useState<boolean>(false);
	const [clearNotifications, setClearNotifications] = useState<boolean>(false);
	const [anchorUserMenuEl, setAnchorUserMenuEl] =
		React.useState<null | HTMLElement>(null);
	const [anchorNotifyEl, setAnchorNotifyuEl] =
		React.useState<null | HTMLElement>(null);
	const userMenu = Boolean(anchorUserMenuEl);
	const notifyMenu = Boolean(anchorNotifyEl);
	const handleUserMenuClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorUserMenuEl(event.currentTarget);
	};
	const handleNotifyClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorNotifyuEl(event.currentTarget);
	};
	const [loading, setLoading] = useState<boolean>(false);
	const [currentProgress, setCurrentProgress] = useState<number>(0);

	const layoutStore = useSelector((state: RootStore) => state.layoutReducer);

	const dispatch = useDispatch();
	const history = useHistory();
	const user = getUser();
	const logOut = () => {
		dispatch(Logout());
		clear();
		history.push('/');
	};

	const getData = async () => {
		if (getOrganisationIdFirebase()) {
			const resultData = await GetOrganisationOfUser(user.userId);
			const name = `${getUser()?.fName?.charAt(0)?.toUpperCase()}${getUser()
				?.lName?.charAt(0)
				?.toUpperCase()}`;
			setUserName(name);
			setOrgData(resultData);
			dispatch(ReloadLayout(false));
		}
	};

	const selectOrganisation = async (id: any) => {
		try {
			setShowOrganisationsModal(false);
			setLoading(true);
			const response = await GetOrganisationInfoById(id);
			setCurrentProgress(100);
			await delay(300);
			if (response.organizationName && response.organisationPathFirebase) {
				const token = {
					user: response,
				};
				const encryptedToken = CryptoJS.AES.encrypt(
					JSON.stringify(token),
					'LEAP360'
				).toString();
				if (siteConfigConstants().ORGANIZATION_ASSOCIATED_WITH_DOMAIN) {
					const url = `${
						siteConfigConstants().ORGANIZATION_ASSOCIATED_WITH_DOMAIN
					}/redirectFromLeap360?authenticationToken=${encryptedToken}`;
					window.location.replace(url);
				} else {
					window.location.replace(
						`${siteConfigConstants().REACT_APP_LOGOUT_REDIRECT_URL}/createOrg`
					);
				}
			} else {
				throw new Error('Something went wrong');
			}
		} catch (error) {
			Toast({
				title: 'Session expired. Please login back.',
				type: ToastTypes.ERROR,
			});
			setLoading(false);
		}
	};

	const getNotes = async () => {
		const result = await GetNotifications(getUser()?.userId);
		setNotificationData(result);
		setCount(result?.length);
		dispatch(ReloadLayout(false));
	};
	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		if (layoutStore.Reload_Layout === true && getOrganisationIdFirebase()) {
			getNotes();
		}
	}, [layoutStore.Reload_Layout]);

	const navigateToTaskView = async (val) => {
		const payload = {
			Is_Read: true,
		};
		if (!val?.type) {
			history.push(`/Inbox/Received/Task/Edit/${val?.id}`);
			UpdateReceivedNote(payload, getUser()?.userId, val?.id, val?.Note_Id);
			UpdateSocialUnReadCount(getUser()?.userId, -1);
		} else {
			history.push(`/Inbox/Campaigns/ApprovalPending`);
			await UpdateCampaignDetails(payload, val?.campaignId);
			await UpdateCampaignsUnReadCount(getUser()?.userId, -1);
		}
		dispatch(ReloadLayout(true));
	};

	const clearAllNotifications = async () => {
		setClearNotifications(!clearNotifications);
		await ClearNotifications(getUser()?.userId);
		await UpdateCampaignsUnReadCount(getUser()?.userId, -count);
		dispatch(ReloadLayout(true));
	};
	const copyToClipBoard = () => {
		if (user?.email) {
			copy(user?.email);
			Toast({ title: 'Mail-Id Copied !', type: ToastTypes.SUCCESS });
		}
	};
	const copyIdToClipBoard = () => {
		if (user?.userId) {
			copy(user?.userId);
			Toast({ title: 'UserId Copied !', type: ToastTypes.SUCCESS });
		}
	};
	const copyUserNameToClipBoard = () => {
		const userFullName = `${user?.fName} ${
			user?.fName === user?.lName ? '' : user?.lName
		}`;
		copy(userFullName);
		Toast({ title: 'User Name Copied !', type: ToastTypes.SUCCESS });
	};

	return (
		<>
			{loading && (
				<LinearProgressBar
					delay={6000}
					incrementBy={1}
					currentProgress={currentProgress}
					message='Switching your organizations...'
				/>
			)}
			{orgData.length > 0 && (
				<Modal
					open={showOrganisationsModal}
					setOpen={setShowOrganisationsModal}
					title='Switch Organization'
					size='md'
					content={
						<>
							<div className='grid justify-center md:grid-cols-2 grid-cols-1 gap-6'>
								{orgData.map((org: any, index) => (
									<div
										key={index}
										className='px-6 py-2 rounded-md border-gray-300 hover:border-primary-600 border cursor-pointer w-86 bg-white hover:shadow-inner'
										onClick={() => selectOrganisation(org.Organization_Id)}
									>
										<h1 className='text-lg font-bold text-gray-500 text-center hover:text-primary-600'>
											{org.Org_Name}
										</h1>
									</div>
								))}
							</div>
						</>
					}
					footer={
						<div className='flex items-center space-x-4'>
							<Button
								title='CLOSE'
								onClick={() => setShowOrganisationsModal(false)}
								variant='outlined'
								color='inherit'
							/>
						</div>
					}
					modalId=''
				/>
			)}
			<div className='flex justify-end items-center gap-2'>
				{getUser()?.organisationPathFirebase && (
					<IconButtonComponent
						id='notification'
						ariaLabel='notification'
						onClick={handleNotifyClick}
						size='large'
						content={
							<BadgeComponent
								content={<NotificationsIcon />}
								id='notification-count'
								count={notificationData?.length}
							/>
						}
					/>
				)}
				<IconButtonComponent
					id='usermenu'
					ariaLabel='usermenu'
					onClick={handleUserMenuClick}
					size='large'
					content={<AccountCircle />}
				/>
				<Menu
					id='user-menu'
					anchorEl={anchorUserMenuEl}
					open={userMenu}
					onClose={() => setAnchorUserMenuEl(null)}
					onClick={() => setAnchorUserMenuEl(null)}
					PaperProps={{
						elevation: 0,
						sx: {
							overflow: 'visible',
							filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
							mt: 1.5,
							'& .MuiAvatar-root': {
								width: 32,
								height: 32,
								ml: -0.5,
								mr: 1,
							},
							'&:before': {
								content: '""',
								display: 'block',
								position: 'absolute',
								top: 0,
								right: 14,
								width: 10,
								height: 10,
								bgcolor: 'background.paper',
								transform: 'translateY(-50%) rotate(45deg)',
								zIndex: 0,
							},
						},
					}}
					transformOrigin={{ horizontal: 'right', vertical: 'top' }}
					anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				>
					<MenuItem divider sx={{ cursor: 'default' }}>
						<div className='flex flex-col items-end justify-end text-sm gap-2'>
							<div className='flex items-end'>
								<Icon icon={IconTypes.Person} className='mr-1 text-sm' />
								<p className='text-gray-600 text-xl font-bold leading-5 mr-2'>
									{user?.fName} {user?.fName === user?.lName ? '' : `${user?.lName}`}
								</p>
								<ContentCopyIcon
									className='text-gray-600'
									onClick={copyUserNameToClipBoard}
								/>
							</div>
							{user?.email && (
								<div className='flex items-end'>
									<Icon icon={IconTypes.Email} className='mr-1 text-xs' />
									<p className='italic text-base leading-5 mr-2'>{user?.email}</p>
									<ContentCopyIcon className='text-gray-500' onClick={copyToClipBoard} />
								</div>
							)}

							{user?.userId && (
								<div className='flex items-end'>
									<small className='text-tiny font-semibold mr-1'>ID</small>
									<p className='italic text-base py-1 leading-5 mr-2'>{user?.userId}</p>
									<ContentCopyIcon
										className='text-gray-500'
										onClick={copyIdToClipBoard}
									/>
								</div>
							)}
							<div className='flex items-end'>
								<small className='text-tiny font-semibold mr-1'>
									Version: Feature/1.1.9.3
								</small>
							</div>
						</div>
					</MenuItem>
					<div className='py-2 flex flex-col gap-2'>
						{orgData.length > 1 && (
							<MenuItem>
								<span
									className='text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'
									onClick={() => {
										getData();
										setShowOrganisationsModal(true);
									}}
								>
									Switch Organization
								</span>
							</MenuItem>
						)}
					</div>
					<MenuItem>
						<span
							className='text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full'
							onClick={logOut}
						>
							Log out
						</span>
					</MenuItem>
				</Menu>
				<div className='notification-list-box'>
					<Menu
						id='notification-menu'
						anchorEl={anchorNotifyEl}
						open={notifyMenu}
						onClose={() => setAnchorNotifyuEl(null)}
						onClick={() => setAnchorNotifyuEl(null)}
						PaperProps={{
							elevation: 0,
							sx: {
								overflow: 'visible',
								width: {
									sm: '100%',
									lg: '50%',
								},
								filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
								mt: 1.5,
								'& .MuiAvatar-root': {
									width: 32,
									height: 32,
									ml: -0.5,
									mr: 1,
								},
								'&:before': {
									content: '""',
									display: 'block',
									position: 'absolute',
									top: 0,
									right: 14,
									width: 10,
									height: 10,
									bgcolor: 'background.paper',
									transform: 'translateY(-50%) rotate(45deg)',
									zIndex: 0,
								},
							},
						}}
						transformOrigin={{ horizontal: 'right', vertical: 'top' }}
						anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
					>
						<div className='justify-end'>
							<Button
								onClick={() => clearAllNotifications()}
								title='Clear notifications'
								variant='text'
							/>
						</div>

						{notificationData?.map((item, index) => (
							<div key={index}>
								<MenuItem divider sx={{ whiteSpace: 'pre-wrap' }}>
									<div className='flex items-start gap-2'>
										<AvatarComponent
											id='notify-alt'
											alt='notify-alt'
											content={
												<p className='text-sm font-bold'>
													{item?.type === undefined
														? `${item?.User_First_Name?.charAt(
																0
														  )?.toUpperCase()}${item?.User_Last_Name?.charAt(
																0
														  )?.toUpperCase()}`
														: userName}
												</p>
											}
											sx={{ width: '2rem', height: '2rem', mr: 2 }}
										/>
										<div className='flex flex-col gap-1 flex-1'>
											<div className='flex items-center'>
												<p className='text-gray-700 text-lg font-semibold mr-2'>
													{item?.type
														? `${item?.Campaign_Name} campaign is waiting for your approval`
														: `${item?.User_First_Name} ${item?.User_Last_Name} assigned a task to you`}
												</p>
												<p className='text-gray-400 text-sm'>
													{item?.type
														? getDateTimeWithUnix(item?.Unix_Timestamp, 'MM/DD/YYYY hh:mm a')
														: `${getDateFormat(
																item?.Created_Date_Time,
																'MM/DD/YYYY hh:mm a'
														  )}`}
												</p>
											</div>
											<p
												className='text-gray-500 underline'
												onClick={() => navigateToTaskView(item)}
											>
												{item?.type ? item?.Description : item?.Text}
											</p>
										</div>
									</div>
								</MenuItem>
							</div>
						))}
						{notificationData?.length === 0 && (
							<div className='my-3 mx-3'>
								<h1>No Notifications </h1>
							</div>
						)}
					</Menu>
				</div>
			</div>
		</>
	);
}

export default WelcomeUser;
