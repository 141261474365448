import { getClientDB, getOrganisationIdFirebase } from 'api/AxiosManager';
import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	getDocs,
	query,
	setDoc,
} from 'firebase/firestore';

export const AddPosCoupon = async (payload: any) => {
	try {
		await addDoc(
			collection(getClientDB(), `${getOrganisationIdFirebase()}/POS/Coupons`),
			payload
		);
		return {
			success: true,
			message: 'Coupon added successfully',
		};
	} catch (error) {
		return error;
	}
};

export const UpdatePosCoupon = async (payload: any) => {
	try {
		await setDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}/POS/Coupons`, payload.id),
			payload
		);
		return {
			success: true,
			message: 'Coupon updated successfully',
		};
	} catch (error) {
		return error;
	}
};

export const GetPosCoupons = async () => {
	try {
		const qry = query(
			collection(getClientDB(), `${getOrganisationIdFirebase()}/POS/Coupons`)
		);
		const querySnapshot = await getDocs(qry);
		const data = [];
		querySnapshot.forEach((d) => {
			data.push({
				...d.data(),
				id: d.id,
			});
		});

		return {
			success: true,
			data,
		};
	} catch (error) {
		return error;
	}
};

export const DeletePosCoupon = async (id: any) => {
	try {
		await deleteDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}/POS/Coupons`, id)
		);
		return {
			success: true,
			message: 'Coupon updated successfully',
		};
	} catch (error) {
		return error;
	}
};
