export const CreativesConstants = {
	SELECT_POST_TYPE: 'Select a social post type',
	CREATE_POST: 'Create a status post',
	EDIT_POST: 'Edit a status post',
};

export const SocialConstants = {
	STATUS: 'STATUS',
	POST: 'POST',
	OFFER: 'OFFER',
	EVENT: 'EVENT',
	CTA: 'CTA',
	STORY: 'STORY',
	VIDEO: 'VIDEO',
};

export const SMSConstants = {
	SMS: 'SMS',
	IMAGE: 'IMAGE',
	MEDIA: 'MEDIA',
	REPLY: 'REPLY',
	RESPONSE: 'RESPONSE',
	SMSMEDIAEDIT: 'SMSMEDIAEDIT',
};
export const VoiceConstants = {
	VOICE: 'VOICE',
	VOICEAUDIOEDIT: 'VOICEAUDIOEDIT',
	REPLY: 'REPLY',
	RESPONSE: 'RESPONSE',
	AUDIO: 'AUDIO'
};

export const EmailConstants = {
	Email: 'EMAIL',
};

