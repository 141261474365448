import { getClientDB, getOrganisationIdFirebase } from 'api/AxiosManager';
import {
	addDoc,
	collection,
	doc,
	getDocs,
	query,
	setDoc,
} from 'firebase/firestore';

export const AddPosTeamMember = async (payload: any) => {
	try {
		await addDoc(
			collection(getClientDB(), `${getOrganisationIdFirebase()}/POS/Team_Members`),
			payload
		);

		return {
			success: true,
			message: 'Team member created successfully',
		};
	} catch (error) {
		return error;
	}
};

export const UpdatePosTeamMember = async (payload: any) => {
	try {
		await setDoc(
			doc(
				getClientDB(),
				`${getOrganisationIdFirebase()}/POS/Team_Members`,
				payload.id
			),
			payload
		);
		return {
			success: true,
			message: 'Team member updated successfully',
		};
	} catch (error) {
		return error;
	}
};

export const GetPosTeamMembers = async () => {
	try {
		const qry = query(
			collection(getClientDB(), `${getOrganisationIdFirebase()}/POS/Team_Members`)
		);
		const querySnapshot = await getDocs(qry);
		const data = [];
		querySnapshot.forEach((d) => {
			data.push({
				...d.data(),
				id: d.id,
			});
		});

		return {
			success: true,
			data,
		};
	} catch (error) {
		return error;
	}
};


export const SendPosLoginPin = async () => {
	try {
		const qry = query(
			collection(getClientDB(), `${getOrganisationIdFirebase()}/POS/Team_Members`)
		);
		const querySnapshot = await getDocs(qry);
		const data = [];
		querySnapshot.forEach((d) => {
			data.push({
				...d.data(),
				id: d.id,
			});
		});

		return {
			success: true,
			data,
		};
	} catch (error) {
		return error;
	}
};