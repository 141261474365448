export const SELECT_SCRATCH_TEMPLATE = 'SELECT_SCRATCH_TEMPLATE';

export const DYNAMIC_FORMULA_CREATION = 'DYNAMIC_FORMULA_CREATION';

export const DYNAMIC_FORMULA_CURRENT_POSITION =
	'DYNAMIC_FORMULA_CURRENT_POSITION';

export const DYNAMIC_VARIABLE_QRCODE_PROPERTIES =
	'DYNAMIC_VARIABLE_QRCODE_PROPERTIES';

export const EMOJI_GROUPS = 'EMOJI_GROUPS';
export const EMOJIS = 'EMOJIS';
