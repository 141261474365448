import { Dispatch } from 'redux';
import {
	create,
	getToken,
	setToken,
	clear,
	setUser,
} from '../../api/AxiosManager';
import { Login as login } from '../../api/User';
import {
	UserDispatchTypes,
	LOGGING_IN,
	LOGIN_FAIL,
	LOGGED_OUT,
	LOGIN_SUCCESS,
	USER,
	USER_POSTED,
	USER_FAIL,
	SET_COMPANY_LOGO_UPDATED,
} from './UserActionTypes';

export const Login =
	(email: string, password: string, organization_id: string = '') =>
	async (dispatch: Dispatch<UserDispatchTypes>) => {
		try {
			dispatch({ type: LOGGING_IN });
			await login(email, password, organization_id).then((respones: any) => {
				setToken(respones.token);
				setUser(respones);
				dispatch({ type: USER, user: respones });
			});
		} catch (e) {
			dispatch({ type: LOGIN_FAIL });
		}
	};

export const Logout = () => async (dispatch: Dispatch<UserDispatchTypes>) => {
	try {
		clear();
		dispatch({ type: LOGGED_OUT });
	} catch (e) {
		dispatch({ type: LOGIN_FAIL });
	}
};

export const CookieLogin =
	() => async (dispatch: Dispatch<UserDispatchTypes>) => {
		try {
			dispatch({ type: LOGGING_IN });
			const token = getToken();
			if (token !== null) {
				const instance = create();
				const result = await instance.get(`User`);

				dispatch({ type: USER, user: result.data });
				dispatch({ type: LOGIN_SUCCESS });
			} else {
				dispatch({ type: LOGIN_FAIL });
			}
		} catch (e) {
			dispatch({ type: LOGIN_FAIL });
		}
	};

export const UpdateUser =
	(user: any) => async (dispatch: Dispatch<UserDispatchTypes>) => {
		try {
			dispatch({ type: USER, user });
		} catch (e) {
			dispatch({ type: LOGIN_FAIL });
		}
	};

export const PostUser =
	(user: any) => async (dispatch: Dispatch<UserDispatchTypes>) => {
		try {
			user.profileConfirmed = true;
			const instance = create();
			const result = await instance.put(`/user`, user);
			dispatch({ type: USER_POSTED, user: result.data });
		} catch (e) {
			dispatch({ type: USER_FAIL });
		}
	};

export const SetCompanyLogoUpdated = () => async (dispatch) => {
	dispatch({ type: SET_COMPANY_LOGO_UPDATED });
};
