import Toast, { ToastTypes } from 'components/ToastNotify';
import { GetAllLocations } from 'firebaseApis/locations';
import { AddPosTeamMember } from 'firebaseApis/pos-team-members';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import Button from 'storybook-mui/build/components/Button';
import Modal from 'storybook-mui/build/components/Modal';
import MUiDatePicker from 'storybook-mui/build/components/MUiDatePicker';
import SelectField from 'storybook-mui/build/components/Select';
import InputTextField from 'storybook-mui/build/components/TextField';

function AddTeamMember({
	showAddEditModal,
	setShowAddEditModal,
}: IAddTeamMemberProps) {
	const dispatch = useDispatch();
	const [locationsData, setLocationsData] = React.useState([]);
	const teamMemberForm = useFormik({
		initialValues: {
			First_Name: '',
			Last_Name: '',
			Email: '',
			Phone_Number: '',
			Location: '',
			Role: '',
			Joining_Date: '',
		},
		onSubmit: async (values) => {
			try {
				dispatch(showLoading());
				const response = await AddPosTeamMember(values);
				if (response.success) {
					Toast({ title: response.message, type: ToastTypes.SUCCESS });
				} else {
					Toast({ title: response.message, type: ToastTypes.ERROR });
				}
				dispatch(hideLoading());
				setShowAddEditModal(false);
			} catch (error) {
				dispatch(hideLoading());
				Toast({ title: error.message, type: ToastTypes.ERROR });
			}
		},
	});

	const getLocationsData = async () => {
		try {
			dispatch(showLoading());
			const response = await GetAllLocations();
			if (response.success) {
				const tempLocations = response.data.map((item: any) => ({
					label: item.Location_Name,
					value: item.id,
				}));
				setLocationsData(tempLocations);
			}

			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
			Toast({ title: error.message, type: ToastTypes.ERROR });
		}
	};

	useEffect(() => {
		getLocationsData();
	}, []);

	return (
		<div>
			<Modal
				open={showAddEditModal}
				setOpen={setShowAddEditModal}
				title='Add Team Member'
				content={
					<form onSubmit={teamMemberForm.handleSubmit}>
						<div className='grid grid-cols-2 gap-5 mt-5'>
							<InputTextField
								name='First_Name'
								fieldId='First_Name'
								onChange={teamMemberForm.handleChange}
								value={teamMemberForm.values.First_Name}
								labelName='First Name'
								placeholder='First Name'
								errorMessage={teamMemberForm.errors.First_Name}
								valid={!teamMemberForm.errors.First_Name}
							/>
							<InputTextField
								name='Last_Name'
								fieldId='Last_Name'
								onChange={teamMemberForm.handleChange}
								value={teamMemberForm.values.Last_Name}
								labelName='Last Name'
								placeholder='Last Name'
								errorMessage={teamMemberForm.errors.Last_Name}
								valid={!teamMemberForm.errors.Last_Name}
							/>

							<InputTextField
								name='Email'
								fieldId='Email'
								onChange={teamMemberForm.handleChange}
								value={teamMemberForm.values.Email}
								labelName='Email'
								placeholder='Email'
								errorMessage={teamMemberForm.errors.Email}
								valid={!teamMemberForm.errors.Email}
							/>

							<InputTextField
								name='Phone_Number'
								fieldId='Phone_Number'
								onChange={teamMemberForm.handleChange}
								value={teamMemberForm.values.Phone_Number}
								labelName='Phone Number'
								placeholder='Phone Number'
								errorMessage={teamMemberForm.errors.Phone_Number}
								valid={!teamMemberForm.errors.Phone_Number}
							/>

							<SelectField
								name='Location'
								fieldId='Location'
								onChange={teamMemberForm.handleChange}
								options={locationsData}
								displayField='label'
								placeholder='Select Location'
								valueField='value'
								value={teamMemberForm.values.Location}
								errorMessage={teamMemberForm.errors.Location}
								valid={!teamMemberForm.errors.Location}
							/>
							<SelectField
								name='Role'
								fieldId='Role'
								onChange={teamMemberForm.handleChange}
								options={[
									{
										label: 'Admin',
										value: 'Admin',
									},
									{
										label: 'Manager',
										value: 'Manager',
									},
									{
										label: 'Cashier',
										value: 'Cashier',
									},
								]}
								displayField='label'
								valueField='value'
								placeholder='Select Role'
								value={teamMemberForm.values.Role}
								errorMessage={teamMemberForm.errors.Role}
								valid
							/>
							<MUiDatePicker
								name='Joining_Date'
								fieldId='Joining_Date'
								onChange={(date: any) => {
									teamMemberForm.setFieldValue('Joining_Date', date);
								}}
								selected={teamMemberForm.values.Joining_Date}
								value={teamMemberForm.values.Joining_Date}
								labelName='Joining Date'
								errorMessage={teamMemberForm.errors.Joining_Date}
								valid={!teamMemberForm.errors.Joining_Date}
							/>

							<div className='col-span-2 flex justify-end gap-5'>
								<Button
									title='Cancel'
									onClick={() => setShowAddEditModal(false)}
									variant='outlined'
									color='primary'
								/>
								<Button
									title='Save'
									type='submit'
									onClick={() => {}}
									variant='contained'
									color='primary'
								/>
							</div>
						</div>
					</form>
				}
				modalId='addTeamMember'
				size='md'
			/>
		</div>
	);
}

export default AddTeamMember;

export interface IAddTeamMemberProps {
	showAddEditModal: boolean;
	setShowAddEditModal: (value: boolean) => void;
}
