import {
	SELECTED_PAGE,
	SELECTED_PAGE_CONTENT,
	SELECTED_WEBSITE,
	TOTAL_PAGES,
	BOOKING_NAV,
	DEFAULT_HEADER,
	DEFAULT_FOOTER,
	ACTIVE_STICKY,
	SHOW_SECTIONS,
	SHOW_ROWS,
	SHOW_CELLS,
} from './WebsiteBuilderActionTypes';

interface IBuilder {
	selectedPage: any;
	selectedWebsite: any;
	selectedPageContent: any;
	totalPages: [];
	bookingNavigation: {};
	defaultHeader: {};
	stickyType: string;
	selectedDefaultHeader: {};
	selectedDefaultFooter: {};
	sectionActive: boolean;
	rowActive: boolean;
	cellActive: boolean;
}

const initialState: IBuilder = {
	selectedPage: {},
	selectedPageContent: {},
	selectedWebsite: {},
	totalPages: [],
	bookingNavigation: {},
	defaultHeader: {},
	stickyType: '',
	selectedDefaultHeader: {},
	selectedDefaultFooter: {},
	sectionActive: false,
	rowActive: false,
	cellActive: false,
};

const WebsiteBuilder = (state: any = initialState, action): IBuilder => {
	switch (action.type) {
		case SELECTED_WEBSITE:
			return { ...state, selectedWebsite: action.payload };
		case SELECTED_PAGE:
			return { ...state, selectedPage: action.payload };

		case SELECTED_PAGE_CONTENT:
			return { ...state, selectedPageContent: action.payload };

		case TOTAL_PAGES:
			return { ...state, totalPages: action.payload };

		case BOOKING_NAV:
			return { ...state, bookingNavigation: action.payload };
		case DEFAULT_HEADER:
			return { ...state, selectedDefaultHeader: action.payload };

		case DEFAULT_FOOTER:
			return { ...state, selectedDefaultFooter: action.payload };

		case ACTIVE_STICKY:
			return { ...state, stickyType: action.payload };

		case SHOW_SECTIONS:
			return { ...state, sectionActive: action.payload };
		case SHOW_ROWS:
			return { ...state, rowActive: action.payload };
		case SHOW_CELLS:
			return { ...state, cellActive: action.payload };

		default:
			return state;
	}
};

export default WebsiteBuilder;
