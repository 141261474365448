import { create } from './AxiosManager';

export const Login = (
	email: string,
	password: string | null,
	organization_id
) =>
	new Promise((resolve, reject) => {
		const payload = {
			email,
			password,
			organization_id,
		};
		const instance = create();
		instance
			.post(`/prescreptiveUsers/login`, payload)
			.then((result) => {
				resolve(result.data);
			})
			.catch((error) => {
				reject(error);
			});
	});

export const checkPasscodeMatched = (email: string, passCode: string) =>
	new Promise((resolve, reject) => {
		const payload = {
			email,
			passCode,
		};
		const instance = create();
		instance
			.post(`/prescreptiveUsers/checkPasscodeMatched`, payload)
			.then((result) => {
				resolve(result.data);
			})
			.catch((error) => {
				reject(error);
			});
	});

export const getPhoneForForgetPassword = (email: string) =>
	new Promise((resolve, reject) => {
		const payload = {
			email,
		};
		const instance = create();
		instance
			.post(`/prescreptiveUsers/getPhoneForForgetPassword`, payload)
			.then((result) => {
				resolve(result.data);
			})
			.catch((error) => {
				reject(error);
			});
	});

export const ForgotPassword = (
	email: string,
	type: string,
	mobileNumber: string = ''
) =>
	new Promise((resolve, reject) => {
		const payload = {
			email,
			whereToSend: type,
			mobileNumber,
		};
		const instance = create();
		// ? Find Forgot Password Endpoint
		instance
			.post(`/prescreptiveUsers/forgotPasswordSendEmail`, payload)
			.then((result) => {
				resolve(result.data);
			})
			.catch((error) => {
				reject(error);
			});
	});

export const ResetPassword = (
	email: string,
	passCode: string,
	password: string
) =>
	new Promise((resolve, reject) => {
		const payload = {
			email,
			passCode,
			password,
		};
		const instance = create();
		instance
			.post(`/prescreptiveUsers/resetPassword`, payload)
			.then((result) => {
				resolve(result.data);
			})
			.catch((error) => {
				reject(error);
			});
	});

export const GetCountryCodes = () =>
	new Promise((resolve, reject) => {
		const instance = create();
		instance
			.get(`/siteconfiguration/getCountryWisePhoneCode`)
			.then((result) => {
				const data = result.data.filter(
					(x: any, i: any, arr: any) =>
						arr.findIndex((y: any) => y.phone_code === x.phone_code) === i
				);
				const filteredData = data.sort((a: any, b: any) =>
					Number(a.phone_code.replace('+', '')) >
					Number(b.phone_code.replace('+', ''))
						? 1
						: -1
				);
				resolve(filteredData);
			})
			.catch((error) => {
				reject(error);
			});
	});
