import React, { useEffect, useState } from 'react';
import Card from 'storybook-mui/build/components/Card';
import IconButtonComponent from 'storybook-mui/build/components/IconButton';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';
import InputTextField from 'storybook-mui/build/components/TextField';
import Button from 'storybook-mui/build/components/Button';
import Modal from 'storybook-mui/build/components/Modal';
import {
	GetOrganisationOfUser,
	GetAllCountries,
} from 'firebaseApis/authentication';
import SelectField from 'storybook-mui/build/components/Select';
import {
	generateQRCode,
	sendPhoneNumber,
	verifyAuthenticatorOTP,
	verifyPhoneAuth,
} from 'api/TwoFactorAuthentication';
import { clear, getUser, getUserId, setUser } from 'api/AxiosManager';

import GoogleAuthIcon from 'assets/img/google_authenticator.png';
import PhoneIcon from 'assets/img/cell-phone.svg';
import AppStoreBtn from 'assets/img/app-store-btn.png';
import PlayStoreBtn from 'assets/img/play-store-btn.png';
import GoogleScanQR from 'assets/img/google-auth-scan-qa.jpeg';
import {
	GetDocumentInfo,
	GetUserDocInfo,
	isAuthenticationVerified,
	UpdateFirstLoginUsers,
} from 'firebaseApis/twoFactorAuthenticationFirebase';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import Toast, { ToastTypes } from 'components/ToastNotify';
import { useHistory } from 'react-router';
import CountrySelect from 'components/Phone/CountrySelect';
import PhoneNumber from 'components/Phone/PhoneNumber';
import { delay } from 'helpers/utils';
import LinearProgressBar from 'components/LinearProgressBar';

const Security = () => {
	const [twoFAmodal, setTwoFAModal] = useState<boolean>(true);
	const [autheticatorModal, setAutheticatorModal] = useState<boolean>(false);
	const [showQRModal, setShowQRModal] = useState<boolean>(false);
	const [QRCodeImage, setQRCodeImage] = useState<string>('');
	const [QRCodeValue, setQRCodeValue] = useState<number>();
	const [loading, setLoading] = useState<boolean>(false);
	const [currentProgress, setCurrentProgress] = useState<number>(0);

	const [enterQRCodeModal, setEnterQRCodeModal] = useState<boolean>(false);

	const [phoneNumberModal, setPhoneNumberModal] = useState<boolean>(false);
	const [phoneCodeModal, setPhoneCodeModal] = useState<boolean>(false);
	const [SMSAuthCode, setSMSAuthCode] = useState<number>();
	const [isGoogleAuthVerified, setisGoogleAuthVerified] =
		useState<boolean>(false);
	const [isSMSAuthVerified, setisSMSAuthVerified] = useState<boolean>(false);
	const [backupModal, setBackupModal] = useState<boolean>(false);
	const [phoneType, setPhoneType] = useState<string>('Mobile');
	const [countries, setCountries] = useState<any>([]);
	const [countryId, setCountryId] = useState<string>('US');
	const [phoneValue, setPhoneValue] = useState<string>('');
	const [extValue, setExtValue] = useState<string>('');

	const user = getUserId();
	const dispatch = useDispatch();
	const history = useHistory();

	// generating qr code
	const getQRCode = async () => {
		const result = await generateQRCode({ userid: user });
		setQRCodeImage(result.qrcode);
	};

	// get document Id from firebase

	const getDocInfo = async () => {
		dispatch(showLoading());
		const result = await GetDocumentInfo();
		dispatch(hideLoading());
		if (result) {
			setisGoogleAuthVerified(result.isGoogleAuthVerfied);
			setisSMSAuthVerified(result.isSMSAuthVerfied);
		}
	};

	useEffect(() => {
		getDocInfo();
	}, []);

	const redirectToCreateOrChooseOrgPage = async (userId) => {
		try {
			dispatch(showLoading());
			const resultData = await GetOrganisationOfUser(userId);
			dispatch(hideLoading());
			if (resultData.length === 0) {
				history.push('/createorg');
			} else {
				history.push('/chooseorg');
			}
		} catch (err) {
			dispatch(hideLoading());
			history.push('/');
		}
	};

	// accept risk

	const handleRisk = async () => {
		dispatch(showLoading());
		const data = { acceptRisk: true, remindMe: false };
		const res = await GetDocumentInfo();
		await isAuthenticationVerified(res.id, data);
		dispatch(hideLoading());
	};

	const acceptRiskFun = () => {
		handleRisk();
		redirectToCreateOrChooseOrgPage(getUserId());
	};

	// remind me

	const handleRemind = async () => {
		dispatch(showLoading());
		const data = { remindMe: true, acceptRisk: false };
		const res = await GetDocumentInfo();
		await isAuthenticationVerified(res.id, data);
		dispatch(hideLoading());
	};

	const remindMeFun = () => {
		handleRemind();
		redirectToCreateOrChooseOrgPage(getUserId());
	};

	// set backup 2FA

	const setBackupFun = () => {
		setTwoFAModal(true);
		setBackupModal(false);
	};

	// update firebase isGoogleAuthVerified

	const handleGoogleAuth = async () => {
		const data = { isGoogleAuthVerfied: true };
		const res = await GetDocumentInfo();
		await isAuthenticationVerified(res.id, data);
		const firstLoginData = { firstLogin: false };
		const result = await GetUserDocInfo();
		await UpdateFirstLoginUsers(result.id, firstLoginData);
	};

	const backupGoogle = async () => {
		const res = await GetDocumentInfo();
		if (res.isSMSAuthVerfied === true) {
			setBackupModal(false);
			redirectToCreateOrChooseOrgPage(getUserId());
		} else {
			setBackupModal(true);
		}
	};

	const backupSMS = async () => {
		const res = await GetDocumentInfo();
		if (res.isGoogleAuthVerfied === true) {
			setBackupModal(false);
			redirectToCreateOrChooseOrgPage(getUserId());
		} else {
			setBackupModal(true);
		}
	};

	const setLocalData = () => {
		const local = getUser();
		setUser({ ...local, firstLogin: false });
	};

	// verify QRcode OTP

	const verifyAuthOTP = async () => {
		setLoading(true);
		const res = await verifyAuthenticatorOTP({
			userid: user,
			token: QRCodeValue,
		});
		if (res.verfied === true) {
			Toast({
				title: 'Two factor authentication is successfully enabled',
				type: ToastTypes.SUCCESS,
			});
			await handleGoogleAuth();
			await backupGoogle();
			setEnterQRCodeModal(false);
			setLocalData();
		} else {
			Toast({
				title: 'Incorrect code',
				type: ToastTypes.ERROR,
			});
		}
		setCurrentProgress(100);
		await delay(300);
		setLoading(false);
		setCurrentProgress(0);
	};

	// update firebase isSMSAuthVerified

	const handleSMSAuth = async () => {
		const countryPhoneCode = countries.find(
			(item) => item.countryId === countryId
		).phoneCode;
		const finalPhoneNumber = `${countryPhoneCode}${phoneValue}`;
		const data = { isSMSAuthVerfied: true, Phone: finalPhoneNumber };
		const res = await GetDocumentInfo();
		await isAuthenticationVerified(res.id, data);
		const firstLoginData = { firstLogin: false };
		const result = await GetUserDocInfo();
		await UpdateFirstLoginUsers(result.id, firstLoginData);
	};

	// get phone number

	const getPhoneNumber = async () => {
		const countryPhoneCode = countries.find(
			(item) => item.countryId === countryId
		).phoneCode;

		await sendPhoneNumber({
			userid: user,
			phone: `${countryPhoneCode}${phoneValue}`,
		});
	};

	// send SMS Auth code

	const verifySMSAuthCode = async () => {
		setLoading(true);
		const result = await verifyPhoneAuth({
			userid: user,
			token: SMSAuthCode,
		});

		if (!result) {
			Toast({
				title: 'Incorrect code',
				type: ToastTypes.ERROR,
			});
		} else {
			Toast({
				title: 'Two factor authentication is successfully enabled',
				type: ToastTypes.SUCCESS,
			});
			await handleSMSAuth();
			await backupSMS();
			setPhoneCodeModal(false);
			setLocalData();
		}
		setCurrentProgress(100);
		await delay(300);
		setLoading(false);
		setCurrentProgress(0);
	};

	// fetching country codes

	const getCountryCodes = async () => {
		dispatch(showLoading());
		const result = await GetAllCountries();
		dispatch(hideLoading());
		const countriesResult: any = result.map((item) => ({
			value: `${item.country_id}`,
			label: item.country_name,
			states: item.states,
			countryId: item.country_id,
			phoneFormat: item.phone_format,
			phoneCode: item.phone_code,
			flagUrl: item.flag_svg_url,
		}));
		setCountries(countriesResult);
	};

	useEffect(() => {
		getCountryCodes();
	}, []);

	const openAuthenticator = () => {
		setTwoFAModal(false);
		setAutheticatorModal(true);
		getQRCode();
	};

	const showQR = () => {
		setShowQRModal(true);
		setAutheticatorModal(false);
	};

	const enterQRCode = () => {
		setEnterQRCodeModal(true);
		setShowQRModal(false);
		setAutheticatorModal(false);
	};

	const openPhoneNumber = () => {
		setTwoFAModal(false);
		setPhoneNumberModal(true);
	};

	const openPhoneCode = () => {
		setPhoneCodeModal(true);
		setPhoneNumberModal(false);
		getPhoneNumber();
	};

	const logout = () => {
		dispatch(showLoading());
		clear();
		history.push('/');
		dispatch(hideLoading());
	};

	return (
		<div className='grid grid-cols-1 lg:grid-cols-3 gap-6 my-6'>
			{loading && (
				<LinearProgressBar
					delay={6000}
					incrementBy={1}
					currentProgress={currentProgress}
					message='Verifying...'
				/>
			)}
			{/* 2-FA modal on load */}

			<Modal
				title={
					<h2 className='text-secondary-500 text-2xl font-semibold text-center'>
						Two-Factor Authentication
					</h2>
				}
				size='sm'
				modalId='on-load-modal'
				open={twoFAmodal}
				setOpen={() => {}}
				content={
					<div className='flex flex-col items-center gap-6'>
						<div className='flex flex-col items-center gap-6'>
							<p className='text-gray-darkest text-sm text-center'>
								Use two-factor authentication (2FA) to add to add an extra layer of
								security to your account.
							</p>
							<div>
								<p className='text-xl text-gray-darkest'>
									Choose how you want to setup 2FA:
								</p>
							</div>
							<div>
								<Card
									headerSx={{ p: 0 }}
									content={
										<div className='flex flex-col justify-center items-center gap-6'>
											<div className='flex flex-row justify-center items-center gap-6'>
												{isGoogleAuthVerified === true ? (
													<Icon
														icon={IconTypes.CheckBoxFilled}
														className='text-green-500 font-bold text-2xl w-8 h-8'
													/>
												) : (
													<IconButtonComponent
														ariaLabel='gg-authenticator'
														id='gg-authenticator'
														content={<img src={GoogleAuthIcon} width='50' height='50' />}
														onClick={() => {
															openAuthenticator();
														}}
														size='large'
														color='info'
													/>
												)}
												<div className='flex justify-start items-center gap-1 lg:h-20'>
													<h4 className='text-gray-darkest font-semibold text-xl'>
														Setup 2FA via Google Authenticator App
													</h4>
												</div>
											</div>
										</div>
									}
								/>

								<Card
									headerSx={{ p: 0 }}
									content={
										<div>
											<div className='flex flex-row justify-start items-center gap-6'>
												{isSMSAuthVerified === true ? (
													<Icon
														icon={IconTypes.CheckBoxFilled}
														className='text-green-500 font-bold text-2xl w-8 h-8'
													/>
												) : (
													<IconButtonComponent
														ariaLabel='sms-title'
														id='sms-title'
														content={<img src={PhoneIcon} width='50' height='50' />}
														onClick={() => {
															openPhoneNumber();
														}}
														size='large'
														color='info'
													/>
												)}
												<div className='flex flex justify-start items-center gap-1 lg:h-20'>
													<h4 className='text-gray-darkest font-semibold text-xl'>
														Setup 2FA via Text Message
													</h4>
												</div>
											</div>
											<div className='flex justify-center mt-4'>
												<Button
													title='Back'
													size='small'
													onClick={() => {
														logout();
													}}
												/>
											</div>
										</div>
									}
								/>
							</div>
						</div>
					</div>
				}
			/>

			{/* open authenticator modal */}
			<Modal
				title={
					<h2 className='text-secondary-500 text-2xl font-semibold text-center'>
						Google Authenticator 2FA
					</h2>
				}
				size='xs'
				modalId='open-2FA'
				open={autheticatorModal}
				setOpen={() => {}}
				content={
					<div className='flex flex-col items-center gap-6'>
						<div className='flex flex-col items-center gap-6'>
							<p className='text-center text-xl text-gray-darkest'>
								Download the Google Authenticator App.
							</p>
							<div className='grid grid-cols-2 gap-4'>
								<img
									src={AppStoreBtn}
									className='cursor-pointer'
									alt='appstore'
									onClick={() => {
										window.open(
											'https://apps.apple.com/us/app/google-authenticator/id388497605'
										);
									}}
								/>
								<img
									src={PlayStoreBtn}
									alt='playstore'
									className='cursor-pointer'
									onClick={() => {
										window.open(
											'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
										);
									}}
								/>
							</div>
							<p className='text-primary-500 font-black italic'>
								Click on next when done.
							</p>
						</div>
						<div className='flex justify-center'>
							<Button
								title='Next'
								size='small'
								onClick={() => {
									showQR();
								}}
							/>
						</div>
					</div>
				}
			/>

			{/* open show qr modal */}
			<Modal
				title={
					<h2 className='text-secondary-500 text-2xl font-semibold text-center'>
						Google Authenticator 2FA
					</h2>
				}
				size='sm'
				modalId='show-QR-modal'
				open={showQRModal}
				setOpen={() => {}}
				content={
					<div className='flex flex-col items-center gap-6'>
						<div className='flex flex-col items-center gap-6'>
							<p className='text-xl text-gray-darkest'>
								Scan QR code on the Google Authenticator app.
							</p>
							<div className='grid grid-cols-1 lg:grid-cols-2 gap-5 divide-x-2'>
								<div className='flex flex-col gap-2'>
									<p className='text-gray-darkest text-sm text-center'>
										Open the google authenticator app, and click on scan a QR code.
									</p>
									<img
										src={GoogleScanQR}
										className='border border-gray-darkest h-auto p-1'
									/>
								</div>
								<div className='flex flex-col items-center'>
									<p className='text-gray-darkest text-sm text-center'>Scan QR code</p>
									{QRCodeImage && (
										<img
											src={QRCodeImage}
											className='border border-gray-darkest h-auto p-1'
										/>
									)}
								</div>
							</div>
						</div>
						<div className='flex justify-center items-center gap-4'>
							<Button
								title='Back'
								size='small'
								onClick={() => {
									setAutheticatorModal(true);
									setShowQRModal(false);
								}}
								variant='outlined'
								color='inherit'
							/>
							<Button
								title='Next'
								size='small'
								onClick={() => {
									enterQRCode();
								}}
							/>
						</div>
					</div>
				}
			/>

			{/* enter QR code modal */}
			<Modal
				title={
					<h2 className='text-secondary-500 text-2xl font-semibold text-center'>
						Google Authenticator 2FA
					</h2>
				}
				sx={{ '&.MuiDialog-root': { zIndex: loading ? -1 : 1304 } }}
				size='xs'
				modalId='enter-QR-code-modal'
				open={enterQRCodeModal}
				setOpen={() => {}}
				content={
					<div className='flex flex-col items-center gap-6'>
						<div className='flex flex-col items-center gap-6'>
							<div>
								<p className='text-xl text-gray-darkest'>
									Verify Google Authenticator app.
								</p>
								<p className='text-gray-dark text-xs'>
									Enter 6-digit code that appears in the Google Authenticator app.
								</p>
							</div>
							<InputTextField
								labelName='Enter 6-digit code'
								name='codesix'
								fieldId='codesix'
								onChange={(e) => {
									setQRCodeValue(e.target.value);
								}}
								value={QRCodeValue}
								size='medium'
							/>
						</div>
						<div className='flex justify-center'>
							<Button
								title='Verify'
								size='small'
								onClick={() => {
									verifyAuthOTP();
								}}
							/>
						</div>
					</div>
				}
			/>

			{/* open phone number modal */}
			<Modal
				title={
					<h2 className='text-secondary-500 text-2xl font-semibold text-center'>
						Where do you want to receive your codes?
					</h2>
				}
				size='sm'
				modalId='open-phone-modal'
				open={phoneNumberModal}
				setOpen={() => {}}
				content={
					<div className='flex flex-col items-center gap-6'>
						<div className='flex flex-col items-center gap-6'>
							<p className='text-center text-xl text-gray-darkest'>
								You will get a one-time code to make sure its working
							</p>

							<div className='flex flex-row gap-2 w-full'>
								<div className='w-1/4'>
									<SelectField
										placeholder='Phone type'
										value={phoneType}
										options={[
											{ value: 'Mobile', label: 'Mobile' },
											{ value: 'Landline', label: 'Landline' },
											{ value: 'Work', label: 'Work' },
										]}
										name='PhoneType'
										fieldId='PhoneType'
										displayField='label'
										valueField='value'
										onChange={(e) => setPhoneType(e.target.value)}
									/>
								</div>

								{countries.length > 0 && (
									<div className='w-1/2'>
										<CountrySelect
											countries={countries}
											countryId={countryId}
											setCountryId={setCountryId}
										/>
									</div>
								)}
							</div>
							<div className='flex flex-row gap-4 w-full'>
								<div className='w-1/2'>
									<PhoneNumber
										mask={
											countries.find((item) => item.countryId === countryId)?.phoneFormat
										}
										phoneValue={phoneValue}
										setPhoneValue={setPhoneValue}
									/>
								</div>

								{phoneType === 'Landline' && (
									<div>
										<InputTextField
											labelName='Ext.'
											variant='outlined'
											value={extValue}
											onChange={(e) => setExtValue(e.target.value)}
											name='Ext'
											fieldId='Ext'
										/>
									</div>
								)}
							</div>
						</div>
						<div className='flex justify-center'>
							<Button
								title='Next'
								size='small'
								onClick={() => {
									openPhoneCode();
								}}
							/>
						</div>
					</div>
				}
			/>

			{/* open phone code modal */}

			<Modal
				title={
					<h2 className='text-secondary-500 text-2xl font-semibold text-center'>
						Verify your SMS number
					</h2>
				}
				sx={{ '&.MuiDialog-root': { zIndex: loading ? -1 : 1304 } }}
				size='sm'
				modalId='open-phone-modal'
				open={phoneCodeModal}
				setOpen={() => {}}
				content={
					<div className='flex flex-col items-center gap-6'>
						<div className='flex flex-col items-center gap-6'>
							<p className=' text-lg text-gray-darkest'>
								Enter the code sent to your phone number to make sure everything works.
							</p>
							<div className='flex flex-col gap-2'>
								<InputTextField
									labelName='6-digit code'
									name='code'
									fieldId='code'
									onChange={(e) => {
										setSMSAuthCode(e.target.value);
									}}
									value={SMSAuthCode}
									size='medium'
								/>
								<Button
									title='Send a new code'
									size='small'
									variant='text'
									color='info'
									onClick={() => {
										getPhoneNumber();
									}}
								/>
							</div>
						</div>
						<div className='flex justify-center'>
							<Button
								title='Verify'
								size='small'
								onClick={() => {
									verifySMSAuthCode();
								}}
							/>
						</div>
					</div>
				}
			/>

			{/* back up Modal */}

			<Modal
				title={
					<h2 className='text-secondary-500 text-2xl font-semibold text-center'>
						Setup Backup 2FA
					</h2>
				}
				size='sm'
				modalId='back-up-modal'
				open={backupModal}
				setOpen={() => {
					setBackupModal(false);
				}}
				content={
					<div className='flex flex-col items-center gap-6'>
						<div className='flex flex-col items-center gap-6'>
							<p className=' text-lg text-gray-darkest'>
								If you lose your first two-factor authentication, set up a second one to
								make your account more secure.
							</p>
							<div className='flex justify-center items-center gap-4 mt-6'>
								<Button
									title='Accept Risk'
									size='small'
									onClick={() => {
										acceptRiskFun();
									}}
									variant='outlined'
									color='inherit'
								/>
								<Button
									title='Remind Me Later'
									size='small'
									variant='outlined'
									color='inherit'
									onClick={() => {
										remindMeFun();
									}}
								/>
								<Button
									title='Setup Backup 2FA'
									size='small'
									onClick={() => {
										setBackupFun();
									}}
								/>
							</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Security;
