export const GET_RELATION_TYPES = 'GET_RELATION_TYPES';
export const GET_PEOPLE_SEARCH = 'GET_PEOPLE_SEARCH';
export const GET_AVAILABLE_LEADS = 'GET_AVAILABLE_LEADS';
export const GET_DEFAULT_VALUES = 'GET_DEFAULT_VALUES';
export const GET_PACKAGES = 'GET_PACKAGES';
export const CLEAR_RELATION_TYPES = 'CLEAR_RELATION_TYPES';
export const SET_SELECTED_ATTRIBUTE = 'SET_SELECTED_ATTRIBUTE';
export const SET_SELECTED_ATTRIBUTES = 'SET_SELECTED_ATTRIBUTES';
export const DELETE_SELECTED_ATTRIBUTES = 'DELETE_SELECTED_ATTRIBUTES';
export const UPDATE_VIEW = 'UPDATE_VIEW';
export const SET_LEAD_CUSTOMERS = 'SET_LEAD_CUSTOMERS';
export const ERROR = 'ERROR';
